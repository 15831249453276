<template>
<div class="game-tips-wrap">

  <div class="game-tips">
    <div class="tip" v-for="tip in tips" :data-id="tip.id" :title="tip.title" @click="tipModal(tip)" :class="{disabled: tip.status==0 || tip.status==-2, expiresActive: tip.seconds_before_expiration}">
      <div class="tip-price" v-if="!tip.seconds_before_expiration"><div class="tip-price-value">{{tip.diamonds}}</div></div>
      <div class="tip-expires" v-else>
        <div class="tip-expires-title">Активно</div>
        <div class="tip-expires-timer">
          <vue-countdown :time="expTime(tip)" v-slot="{ days, hours, minutes, seconds }"  :transform="transformSlotProps"  @end="onCountDownEnd(tip)">
            {{ hours }}:{{ minutes }}:{{ seconds }}
          </vue-countdown>
        </div>
      </div>
      <div class="tip-icon"></div>
    </div>
    <div class="tip trash-btn" title="Удалить последнее слово" v-if="cancelBtn">
      <div class="tip-icon"></div>
    </div>
    <div class="tip check-btn" title="Проверить кроссворд" v-if="checkBtn" @click="check()" :class="{disabled: !checkbtnActive}">
      <div class="tip-icon"></div>
    </div>

  </div>

  <Modal
      v-model="showModal"
      @close="confirmClose"
      @confirm="confirmClose"
  >
    <template #customHeader>
      <div class="current-tip-title">Уверены, что хотите использовать подсказку "{{activeTip.title}}"?</div>
      <div class="current-tip-title-caption" v-if="activeTip.id==21 || activeTip.id==24">
        Подсказка действует 24 часа на данном уровне сложности
      </div>
    </template>
    <div class="current-tip-text">
      С вас будет списано
     <div class="current-tip-price">{{activeTip.diamonds}} кристаллов</div>
    </div>

    <template #customFooter>
      <div class="modal-buttons-large">
        <div class="btn btn-secondary" @click="confirmClose">Нет</div>
        <div class="btn btn-primary" @click="tipActivate" :class="{disabled: loading}">Да</div>
      </div>
    </template>
  </Modal>

  <Modal
      v-model="balanceModal"
      :title="'Не хватает кристаллов'"
      @close="balanceModal = false"
  >
    <div class="buy-popup-text">
      Перейдите в магазин для покупки кристаллов
    </div>

    <template #customFooter>
      <div class="modal-buttons-large mt-0">
        <div class="btn btn-secondary" @click="balanceModal = false">Отмена</div>
        <div class="btn btn-primary" @click="goToStore">В магазин</div>
      </div>
    </template>

  </Modal>

</div>

</template>


<script>
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';
import Modal from '@/components/modals/Modal.vue'
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  name: 'difficulty',
  props: {
    tips: {
      type: Object,
      default: {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    cancelBtn: {
      type: Boolean,
      default: false
    },
    checkBtn: {
      type: Boolean,
      default: false
    },
    checkbtnActive: {
      type: Boolean,
      default: false
    },

  },
  emits: ['buy', 'countDownEnd', 'userCheck'],
  components: {
    Modal,
    VueCountdown
  },
  data() {
    return {
      showModal: false,
      activeTip: {},
      balanceModal: false
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo']),
    errors() {

    },
  },
  created() {
    // console.log(this.tips)
  },
  methods: {
    ...mapActions(useUserStore, ['sudokuGame', 'checkGame', 'getUserInfo', 'sudokuGameContinue', 'setBackUrl']),
    tipModal(tip){
      if (this.userInfo.diamonds >= tip.diamonds){
        this.activeTip = tip;
        this.showModal = true;
      }
      else {
        this.balanceModal = true
      }
    },
    modalClose() {
      this.showModal = false;
    },
    goToStore() {
      setTimeout(() => {
        this.setBackUrl(this.$route.path);
        this.$router.push('/shop');
      }, 100);
      this.balanceModal = false;
    },
    confirmClose() {
      this.showModal = false;
    },
    async tipActivate(){
      this.$emit('buy',this.activeTip)
      this.showModal = false;
    },
    check(){
      this.$emit('userCheck')
    },
    expTime(tip){
      let t1 = new Date();
      let t2 = new Date(tip.expires_at);
      return tip.seconds_before_expiration*1000;
    },
    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
    onCountDownEnd(tip){
      this.$emit('countDownEnd',tip)
    }
  }
};
</script>
