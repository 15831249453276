<template>

  <div class="nonogram-cell" :class="{cross: item.checked}">
      <span v-if="item.text!==''">{{item.text}}</span>
  </div>

</template>


<script>
import { mapState, mapActions } from 'pinia';

export default {
  name: 'NumCell',
  props: {
    item: {
      type: Object,
      default: 0
    },
  },
  // emits: ['pickCell', 'clearCell', 'inputCell'],
  components: {

  },
  data() {
    return {
      crossCell: false
    };
  },
  computed: {

  },
  created() {

  },
  methods: {
    checkCell(){
      this.crossCell = !this.crossCell
    }
  }
};
</script>
