<template>
  <div class="page-container">
    <div class="page-title">Победители акции сервиса</div>
    <div class="page-inner content-layout">
      <div class="content-layout-top">
        <router-link class="go-back" to="/profile">{{ $t("nav.back") }}</router-link>
        <div class="winners-title">{{winnersList.period}}</div>
      </div>
      <div class="content-layout-inner">
        <div class="winners-table-container">
          <table>
            <thead>
            <tr>
              <th>№</th>
              <th>Приз</th>
              <th>Победитель</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in winnersList.winners">
                <td>{{i+1 }}</td>
                <td>{{item.prize}}</td>
                <td>{{item.msisdn}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { RouterLink, RouterView } from 'vue-router'
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'AppHeader',
  components: {
    RouterLink,
    RouterView
  },
  async created() {
    await this.getWinners();
  },
  computed: {
    ...mapState(useUserStore, ['userInfo', 'winners']),
    winnersList() {
     if (this.winners)
       return this.winners
    }
  },
  methods: {
    ...mapActions(useUserStore, ['getWinners']),
  }
};
</script>
