<template>
  <header class="header">
    <router-link to="/shop" class="header-stat-item header-star">{{ userInfo?.points }}</router-link>
    <router-link to="/shop" class="header-stat-item header-diamond">{{ userInfo?.diamonds }}</router-link>
  </header>
</template>

<script>
  import { RouterLink, RouterView } from 'vue-router'
  import { useUserStore } from '@/store/user';
  import { mapState, mapActions } from 'pinia';

  export default {
    name: 'AppHeader',
    components: {
      RouterLink,
      RouterView
    },
    computed: {
      ...mapState(useUserStore, ['userInfo']),
    },
    created() {

    },
  };
</script>
