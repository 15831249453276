<template>
  <div class="page-container">
    <div class="page-title">{{ $t("pages.langs.pageTitle") }}</div>

    <div class="page-inner">
      <div class="langs-list">

      <label class="langs-list-item">
        <input type="radio" v-model="$i18n.locale" :value="'ru'">
        <div class="langs-list-item-content">
          <img src="@/assets/img/locales/ru.png" alt="" class="langs-list-item-img" />
          <div class="langs-list-item-title">Русский</div>
        </div>
      </label>

<!--      <label class="langs-list-item">-->
<!--        <input type="radio" v-model="$i18n.locale" :value="'uz'">-->
<!--        <div class="langs-list-item-content">-->
<!--          <img src="@/assets/img/locales/uz.png" alt="" class="langs-list-item-img" />-->
<!--          <div class="langs-list-item-title">O'zbek</div>-->
<!--        </div>-->
<!--      </label>-->

    </div>
    </div>

  </div>
</template>

<script>

import { RouterLink, RouterView } from 'vue-router'

export default {
  components: {
    RouterLink,
    RouterView
  },
  computed (){

  },
  mounted() {
    // console.log(this.$i18n)
  }
};
</script>
