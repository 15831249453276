<template>

  <RouterLink to="/puzzle" class="puzzle-panel">
    <div class="puzzle-panel-title">Мои пазлы</div>
    <div class="puzzle-panel-pieces">{{ pieces() }} шт.</div>
  </RouterLink>

</template>


<script>
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/store/user';

export default {
  name: 'puzzleProgress',
  props: {
    puzzle: {
      type: Object,
      default: {}
    },
    info: {
      type: Object,
      default: {}
    },

  },
  components: {

  },
  data() {
    return {
      showModal: false,
      hint: false,
      progressValue: 0,
    };
  },
  computed: {
    // ...mapState(useUserStore, ['userInfo']),
  },
  created() {

  },
  methods: {
    pieces () {
      let pieces = 0;
      if (this.info.puzzle.openedItems)
        pieces = this.info.puzzle.openedItems.length
      return pieces;
    }
  }
};
</script>
