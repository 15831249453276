<template>
  <div class="shop-item" v-if="item" :class="{ 'shop-item-star' : star, 'disabled': !isActive }">
    <div class="shop-item-caption">{{ item.diamonds }}</div>
    <div class="shop-item-button" @click="modalOpen">Купить за&nbsp;<span class="shop-item-price">{{ item.price }}</span><span v-if="!star">&nbsp;руб</span></div>

    <Modal
        v-model="showModal"
        :title="$t('pages.shop.popups.buy.title')"
        :btnText="$t('pages.shop.popups.buy.btnText')"
        :buyType="star ? 'star' : 'cash'"
        :btnClass="btnClass"
        @close="modalClose"
        @confirm="modalConfirm"
    >
      <div v-if="star" class="buy-popup-text" v-html='$t("pages.shop.popups.buy.text_star", {price: price})'></div>
      <div v-else class="buy-popup-text" v-html='$t("pages.shop.popups.buy.text", {phone: userPhone, price: price})'></div>
      <div class="buy-popup-reward">
        <div class="buy-popup-reward-title">{{ $t('pages.shop.popups.buy.crystals', item.diamonds) }}</div>
        <div class="buy-popup-reward-img">
          <img src="@/assets/img/crystall-popup.svg" alt="" />
        </div>
      </div>
    </Modal>

    <Modal
        v-model="confirmModal"
        :title="'Покупка кристаллов'"
        :btnText="'Отлично!'"
        @close="confirmClose"
        @confirm="confirmClose"
    >
      <div class="buy-popup-text" v-if="!star">
        Идёт оформление покупки
        <span>{{ item.diamonds }} кристаллов</span> за <strong>{{ item.price }} рублей</strong>.
        В&nbsp;течение пяти минут зачислятся кристаллы. Если возникнут проблемы&nbsp;&mdash; мы&nbsp;вам сообщим
      </div>
      <div class="buy-popup-text" v-else>
        <span>{{ item.diamonds }} кристаллов</span> за <strong class="star">{{ item.price }}</strong> куплены успешно!
        <br><br>
      </div>
      <div class="buy-popup-reward">
        <div class="buy-popup-reward-img">
          <img src="@/assets/img/success.svg" alt="" />
        </div>
      </div>
    </Modal>

    <Modal
        v-model="errorModal"
        :title="'Ошибка'"
        :btnText="'Закрыть'"
        @close="confirmClose"
        @confirm="confirmProcess"
    >
      <div class="buy-popup-text">
        Идёт оформление покупки
        <span>300 кристаллов</span> за <strong>100 рублей</strong>.
        В&nbsp;течение пяти минут зачислятся кристаллы. Если возникнут проблемы&nbsp;&mdash; мы&nbsp;вам сообщим
      </div>
      <div class="buy-popup-reward">
        <div class="buy-popup-reward-img">
          <img src="@/assets/img/success.svg" alt="" />
        </div>
      </div>
    </Modal>

  </div>
</template>


<script>
import { useUserStore } from '@/store/user';
import { useMainStore } from '@/store';
import { mapState, mapActions } from 'pinia';
import Modal from '@/components/modals/Modal.vue'

export default {
  name: 'ShopItem',
  props: {
    item: {
      type: Object,
      default: {}
    },
    star: {
      type: Boolean,
      default: false
    }

  },
  components: {
    Modal
  },
  data() {
    return {
      showModal: false,
      confirmModal: false,
      paymentSuccess: false,
      errorModal: false,
      buyInProcess: false,
      backUrl: ''
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo', 'storeBackUrl']),
    btnClass() {
     return this.buyInProcess ? 'disabled' : '' ;
    },
    userPhone(){
      this.phone = `<strong>+${this.userInfo.msisdn}</strong>`;
      return this.phone;
    },
    isActive(){
      this.isActiveItem = parseInt(this.userInfo.points ) < parseInt( this.item.price) ? false : true;
      this.isActiveItem = this.star ? this.isActiveItem : true;
      return this.isActiveItem;
    }
  },
  created() {

    this.price = `<strong>${this.item.price} рублей</strong>`;
    if (this.star){
      this.price = `<strong class="star">${this.item.price}</strong>`;
    }
    if (this.storeBackUrl != ''){
      this.backUrl = this.storeBackUrl;
    }
  },
  methods: {
    ...mapActions(useUserStore, ['buyDiamods', 'checkProgress' ,'setBackUrl']),
    modalClose() {
      this.showModal = false;
    },
    modalOpen() {
      if (this.userInfo.is_subscribed == 0)
        window.location.href = ('/subscribe_web');
      else
        this.showModal = true;
    },
    confirmClose() {
      this.confirmModal = false;
      this.goBack();
    },
    confirmProcess() {
      this.confirmModal = false;
      this.goBack();
    },
    goBack() {
      this.confirmModal = false;
      if (this.backUrl!=''){
        this.setBackUrl('');
        this.$router.push(this.backUrl)
      }
    },
    async modalConfirm() {

      if (this.buyInProcess) {
        return
      }
      this.buyInProcess = true;
      let res = await this.buyDiamods({
        "sell": this.item.price,
        "diamonds": this.item.diamonds,
        "gameType": "sudoku",
        "type": this.star ? 1 : 2
      })


      this.showModal = false;
      if (res != 'undefined'){
        if (res.data.isSuccess){
          this.confirmModal = true;
        }
        if(res.data.inProgress){
          this.checkProgress({'id': res.data.id});
          this.confirmModal = true;
        }
      }
      this.buyInProcess = false;
    },
  }
};
</script>
