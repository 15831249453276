import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { useAuthStore } from '@/store/auth'
import  ErrorView from '../views/ErrorView.vue'
import  LangsView from '../views/LangsView.vue'
import  ShopView from '../views/ShopView.vue'
import  ProfileView from '../views/ProfileView.vue'
import  winnersView from '../views/winnersView.vue'
import  pageView from '../views/pageView.vue'
import  SudokuView from '../views/SudokuView.vue'
import  SudokuGameView from '../views/SudokuGameView.vue'
import  FillwordsView from '../views/FillwordsView.vue'
import  FillwordsGameView from '../views/FillwordsGameView.vue'
import  PuzzleView from '../views/PuzzleView.vue'
import  NonogramView from '../views/NonogramView.vue'
import  NonogramGameView from '../views/NonogramGameView.vue'


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:pathMatch(.*)',
      name: 'error404',
      component: ErrorView,
      meta: { errorPage: true },
    },
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        transition: 'fade',
        title: 'Home Page - Example App',
        metaTags: [
          {
            name: 'description',
            content: 'The home page of our example app.'
          },
          {
            property: 'og:description',
            content: 'The home page of our example app.'
          }
        ]
      },
    },
    {
      path: '/langs',
      name: 'Langs',
      component: LangsView,
      meta: { transition: 'fade' },
    },
    {
      path: '/shop',
      name: 'Shop',
      component: ShopView,
      meta: { transition: 'fade' },
    },
    {
      path: '/profile',
      name: 'Profile',
      component: ProfileView,
      meta: { transition: 'fade' },
    },
    {
      path: '/winners',
      name: 'Winners',
      component: winnersView,
      meta: { transition: 'fade', hideNavigation: true, hideHeader: true},
    },
    {
      path: '/legal',
      name: 'Legal',
      component: pageView,
      meta: { transition: 'fade', hideNavigation: true, hideHeader: true},
    },
    {
      path: '/sudoku',
      name: 'Sudoku',
      component: SudokuView,
      meta: { transition: 'fade', hideNavigation: true, gameType: 'sudoku' },
    },
    {
      path: '/sudoku/start',
      name: 'SudokuGame',
      component: SudokuGameView,
      meta: { transition: 'fade', hideNavigation: true, gameType: 'sudoku' },
    },
    {
      path: '/fillword',
      name: 'Fillword',
      component: FillwordsView,
      meta: { transition: 'fade', hideNavigation: true, gameType: 'fillword' },
    },
    {
      path: '/fillword/start',
      name: 'FillwordGame',
      component: FillwordsGameView,
      meta: { transition: 'fade', hideNavigation: true, gameType: 'fillword' },
    },
    {
      path: '/puzzle',
      name: 'puzzle',
      component: PuzzleView,
      meta: { transition: 'fade', hideNavigation: true},
    },
    {
      path: '/nonogram',
      name: 'Nonogram',
      component: NonogramView,
      meta: { transition: 'fade', hideNavigation: true, gameType: 'nonogram' },
    },
    {
      path: '/nonogram/start',
      name: 'NonogramGame',
      component: NonogramGameView,
      meta: { transition: 'fade', hideNavigation: true, gameType: 'nonogram' },
    },
  ]
})




export default router
