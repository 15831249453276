<template>

    <Modal
        v-model="showModal"
        :title='`Уровень сложности "${levelName}" пройден!`'
        @close="confirmClose"
        class="modal-wrapper game-win-modal"
    >
        <div class="buy-popup-text">
                Выберите другую игру или новый уровень сложности для продолжения игры.
        </div>

        <template #customFooter>
          <div class="modal-buttons-large mt-0">
            <div @click='toMainPage' class="btn btn-secondary">Главное меню</div>
            <div class="btn btn-primary" @click="confirmClose">Продолжить</div>
          </div>
        </template>

      </Modal>

</template>


<script>
  import { useModal } from 'vue-final-modal'
  import Modal from '@/components/modals/Modal.vue'

  export default {
    name: 'modalPreview',
    props: {
        level: {
            type: String,
            default: ''
        },
        finishedLevel: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        levelName () {
            let levelName = '';
            switch (this.level) {
                case 'low':
                    levelName = 'Легко';
                    break;
                case 'middle':
                    levelName = 'Средне';
                    break;
                case 'high':
                    levelName = 'Сложно';
                    break;    
                case 'ultra':
                    levelName = 'Экстремально';
                    break;                
                default:
                    levelName = '';
                    break;
            }   
            return levelName;
        },
        showModal () {
            return this.finishedLevel
        }
    },
    components: {
     Modal
    },
    methods: {
        confirmClose () {
            this.$emit('closeCompletedLevelModal');
        },
        toMainPage() {
            setTimeout(() => {
                this.$router.push({name: `home`});
            }, 100);
            this.$emit('closeCompletedLevelModal');
        },
    }
  };
</script>
