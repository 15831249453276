<template>
  <div class="page-container">
    <div class="page-title">{{ $t("pages.main.pageTitle") }}</div>
    <div class="page-inner">
      <puzzlePanel :info="userInfo"></puzzlePanel>
      <div class="main-games-grid">
        <RouterLink to="/sudoku" class="game-grid-item sudoku-item">
          <div class="game-title">Судоку</div>
          <div class="game-image-container"></div>
        </RouterLink>
        <RouterLink to="/fillword" class="game-grid-item fillwords-item">
          <div class="game-title">Филворды</div>
          <div class="game-image-container"></div>
        </RouterLink>
        <RouterLink to="/nonogram" class="game-grid-item nonogram-item">
          <div class="game-title">Японские кроссворды</div>
          <div class="game-image-container"></div>
        </RouterLink>
      </div>

      <div class="main-reward-info">
        За любую пройденную игру
        <span class="main-reward-info-item">+<span class="main-reward-icon diamonds">2</span> кристалла</span> и&nbsp;до
        <span class="main-reward-info-item">+<span class="main-reward-icon points">35</span> очков</span>
      </div>

      <Modal
          v-model="announceModal"
          @close="modalClose"
          class="modal-wrapper info-modal"
      >
        <template #customHeader>
          <div class="info-modal-img"></div>
        </template>

        <div class="info-modal-content">
          <p>Пройдите как можно больше уровней и соберите все уникальные элементы пазла в течение месяца. Войдите в первую десятку собравших пазл и получите стакан из лимитированной коллекции билайна!</p>
        </div>

      </Modal>

    </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import Modal from '@/components/modals/Modal.vue'
import puzzlePanel from '@/components/home/puzzlePanel.vue'
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/store/user';

export default {
  name: 'Nav',
  components: {
    RouterLink,
    RouterView,
    Modal,
    puzzlePanel
    // ModalPreview
  },
  data() {
    return {
      announceModal: false,
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo']),
  },
  methods: {
    modalClose() {
      this.announceModal = false
    }
  }
};
</script>
