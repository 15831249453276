<template>

  <div class="fillword-cell" :class="{ locked: item.locked, highlighted: item.highlighted, tipCell: item.tipsCell}" :data-num="item.num"  :data-tipNum="item.tipNum" :data-row="item.row" :data-col="item.col">

     {{item.value}}

  </div>

</template>


<script>
import { mapState, mapActions } from 'pinia';

export default {
  name: 'cell',
  props: {
    item: {
      type: Object,
      default: {}
    },
  },
  // emits: ['pickCell', 'clearCell', 'inputCell'],
  components: {

  },
  data() {
    return {

    };
  },
  computed: {

  },
  created() {

  },
  methods: {
    swipeHandler(item){
      console.log(item.value)
    },

  }
};
</script>
