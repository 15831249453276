<template>
  <div class="error-page-container">
    <div class="error-page-top">
      <div class="error-page-title">Упс!</div>
      <div class="error-page-text">Что-то пошло не так, страница не найдена</div>
      <div class="error-page-img">
        <div class="error-page-type">404</div>
      </div>
    </div>
    <div class="error-page-btn">
      <RouterLink class="btn btn-primary" to="/">На главную</RouterLink>
    </div>
  </div>
</template>

<script>

import { RouterLink, RouterView } from 'vue-router'

export default {
  name: 'AppHeader',
  components: {
    RouterLink,
    RouterView
  },
};
</script>
