<template>
  <div class="page-container">
    <div class="page-title">Японские кроссворды</div>
    <div class="page-inner game-page-inner game-detail" :class="[{'content-loading' : contentLoading}, {'loadLevel' : loadLevel}]">
      <div class="game-detail-top">
        <router-link class="go-back" to="/nonogram">{{ $t("nav.back") }}</router-link>
        <div class="game-difficulty-title">
          №{{ gameInfo.num }} {{ levelTitle(userInfo.games.nonogram.levelName) }}
          <div class="topic" v-if="games.nonogram.content.title">"{{ games.nonogram.content.title }}"</div>
        </div>
       <!-- <button class="game-reset" @click="gameReset()" :class="{disabled: !reset}"> -->
        <button class="game-reset" @click="gameReset()" :class="{disabled: !reset}"> 
         <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
           <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7693 0.36865C13.6194 0.270179 13.4438 0.212481 13.2617 0.201805C13.0796 0.191129 12.8979 0.227881 12.7363 0.308082C12.5747 0.388282 12.4393 0.508876 12.3447 0.656804C12.2502 0.804733 12.2001 0.97436 12.2 1.14732V3.44667C9.82663 3.44667 7.50655 4.1139 5.53316 5.36398C3.55977 6.61407 2.0217 8.39086 1.11345 10.4697C0.2052 12.5485 -0.0324398 14.836 0.430583 17.0428C0.893605 19.2497 2.03649 21.2768 3.71472 22.8678C5.39295 24.4589 7.53115 25.5424 9.85892 25.9814C12.1867 26.4204 14.5995 26.1951 16.7922 25.334C18.9849 24.4729 20.8591 23.0147 22.1776 21.1439C23.4962 19.273 24.2 17.0734 24.2 14.8233C24.2 14.4881 24.0595 14.1666 23.8095 13.9295C23.5594 13.6924 23.2203 13.5593 22.8667 13.5593C22.513 13.5593 22.1739 13.6924 21.9239 13.9295C21.6738 14.1666 21.5333 14.4881 21.5333 14.8233C21.5333 16.5734 20.9859 18.2842 19.9604 19.7393C18.9348 21.1944 17.4772 22.3286 15.7717 22.9983C14.0663 23.668 12.1896 23.8432 10.3792 23.5018C8.56867 23.1604 6.90563 22.3177 5.60034 21.0802C4.29505 19.8427 3.40614 18.266 3.04601 16.5496C2.68588 14.8331 2.87071 13.054 3.57713 11.4372C4.28355 9.8203 5.47983 8.43835 7.01468 7.46606C8.49406 6.52892 10.2239 6.01293 12 5.97685C12.0666 5.97549 12.1333 5.97482 12.2 5.97482V8.90747C12.2004 9.08032 12.2506 9.24978 12.3452 9.39753C12.4399 9.54528 12.5753 9.66569 12.7369 9.74574C12.8985 9.82578 13.0801 9.86243 13.2621 9.8517C13.444 9.84097 13.6194 9.78328 13.7693 9.68487L19.6787 5.80669C19.8118 5.71948 19.9206 5.60296 19.9957 5.46711C20.0709 5.33126 20.1101 5.18016 20.1101 5.02676C20.1101 4.87336 20.0709 4.72226 19.9957 4.58641C19.9206 4.45057 19.8118 4.33404 19.6787 4.24683L13.7693 0.36865Z" fill="#6D78B8"/>
           </svg>

         </svg>

       </button>


      </div>

<!--      <PinchScrollZoom-->
<!--          class="zoom-container"-->
<!--          ref="zoomer"-->
<!--          :width="400"-->
<!--          :height="300"-->
<!--          :within="true"-->
<!--          :min-scale="1"-->
<!--          :max-scale="6"-->
<!--          @scaling="e => onEvent('scaling', e)"-->
<!--          @startDrag="e => onEvent('startDrag', e)"-->
<!--          @stopDrag="e => onEvent('stopDrag', e)"-->
<!--          @dragging="e => onEvent('dragging', e)"-->
<!--          :content-width="900"-->
<!--          :content-height="900"-->
<!--      >-->

      <div class="zoom-container">


        <div class="nonogram-container" :style="{width: containerWidth(), height: containerHeight()}" id="panzoom-element">
          <div class="nonogram-top">
            <div class="nonogram-spacer" v-bind:style="{ gridTemplateColumns: `repeat(${maxRows}, 1fr)` }">
              <div class="nonogram-cell" v-for="item in gridSizeSpacer" :key="item" :style="{width: `${cellWidth()}px`, height: `${cellWidth()}px`}"></div>
            </div>
            <div class="nonogram-left-numbers" v-bind:style="{ gridTemplateColumns: `repeat(${rows.length}, 1fr)`, }">
              <div class="nonogram-row" v-for="(row, index) in rowsGrid" v-bind:style="{ gridTemplateColumns: `repeat(${maxRows}, 1fr)` }">
                <num-cell v-for="(item, ind) in row" :item="item" :style="{width: `${cellWidth()}px`, height: `${cellWidth()}px`, fontSize: `${cellWidth()-2}px`}"  @click="checkNUmCell('rows', index, ind, item)"></num-cell>
              </div>
            </div>
          </div>
          <div class="nonogram-middle">
            <div class="nonogram-top-numbers">
              <div class="nonogram-row" v-for="(col, index) in colsGrid">
                <num-cell v-for="(item, ind) in col" :item="item" :style="{width: `${cellWidth()}px`, height: `${cellWidth()}px`, fontSize: `${cellWidth()-2}px`}" @click="checkNUmCell('cols', index, ind, item)"></num-cell>
              </div>
            </div>
            <div class="nonogram-grid" v-bind:style="{ gridTemplateColumns: `repeat(${gridWidth}, 1fr)`, }">
              <cell v-for="item in gameGrid" :item="item" @click="clickCell(item)" :style="{width: `${cellWidth()}px`, height: `${cellWidth()}px`}"></cell>
            </div>
          </div>
        </div>

      </div>
<!--      </PinchScrollZoom>-->



      <tips :tips="tipsArr" @buy="buy" :loading="contentLoading" :checkBtn="true" :checkbtnActive="checkBtn" @userCheck="check"></tips>

      <Modal
          v-model="winModal"
          :title="'Поздравляем!'"
          @close="confirmClose"
          class="modal-wrapper game-win-modal"
      >
        <div class="buy-popup-text">
          Вы прошли уровень №{{ gameInfo.num }}<br>
          Ваши призы:
        </div>
        <div class="game-rewards" v-if="prizes">
          <div class="reward points" v-if="prizes.points">+{{prizes.points}}</div>
          <div class="reward diamonds" v-if="prizes.diamonds">+{{prizes.diamonds}}</div>
          <div class="reward puzzle" v-if="prizes.puzzle">+{{prizes.puzzle}}</div>
        </div>

        <template #customFooter>
          <div class="modal-buttons-large mt-0">
            <div @click="toMainPage" class="btn btn-secondary">Главное меню</div>
            <div class="btn btn-primary" @click="nextlevel()">Следующий уровень</div>
          </div>
        </template>

      </Modal>

      <Modal
          v-model="failModal"
          :title="'Неудача!'"
          @close="nextlevel()"
      >
        <div class="buy-popup-text" v-if="notFinal">
          Вы неправильно заполнили японский кроссворд, попробуйте ещё раз
        </div>
        <div class="buy-popup-text" v-else>
          Вы не правильно заполнили японский кроссворд, попробуйте пройти следующий уровень
        </div>
        <div class="attemps-left" v-if="notFinal">
          Осталось попыток: {{attempsLeft}}
        </div>
        <div class="buy-popup-reward" v-if="!notFinal">
          <img src="@/assets/img/lose.png" class="game-fail-img" alt="fail" />
        </div>

        <template #customFooter v-if="notFinal">
          <div class="modal-buttons-large mt-0">
            <div  @click="toChooseLevel" class="btn btn-secondary">К выбору уровня</div>
            <div @click="closeModal" class="btn btn-primary">Продолжить</div>
          </div>
        </template>
        <template #customFooter v-else>
          <div class="modal-buttons-large mt-0">
            <div @click="toMainPage" class="btn btn-secondary">Главное меню</div>

            <div :to="`/${gameType}`" @click="nextlevel()" class="btn btn-primary">Следующий уровень</div>
          </div>
        </template>

      </Modal>

      <Modal
          v-model="checkModal"
          :title="'Проверка!'"
          @close="closeModal"
      >
        <div class="buy-popup-text">
          Осторожно! Нажатие на кнопку "Проверить" может стереть все ваши достижения. Будьте внимательны и продвигайтесь к цели без ошибок.
        </div>

        <template #customFooter>
          <div class="modal-buttons-large mt-0">
            <div class="btn btn-secondary" @click="closeModal()">Отмена</div>
            <div class="btn btn-primary" @click="check">Проверить</div>
            <!--            <button @click="startNew" tabindex="-1" class="btn btn-primary">Следующий уровень</button>-->
          </div>
        </template>

      </Modal>

    </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';
import cell from '@/components/games/nonogram/cell.vue'
import NumCell from '@/components/games/nonogram/numCell.vue'
import tips from '@/components/games/tips.vue'
import Modal from '@/components/modals/Modal.vue'
import promoItem from '@/components/promo/promoItem.vue';
import Panzoom from '@panzoom/panzoom';
import PinchScrollZoom from '@coddicat/vue-pinch-scroll-zoom';

export default {
  name: 'NonogramGame',
  components: {
    RouterLink,
    RouterView,
    cell,
    NumCell,
    tips,
    Modal,
    promoItem,
    PinchScrollZoom
  },
  data() {
    return {
      gridSize: 3,
      gridWidth: 10,
      gridHeight: 10,
      gridSizeBlock: 0,
      gridSizeSpacer: 0,
      sidebarWidth: 0,
      totalStr: '',
      totalValues: 0,
      rows: [],
      rowsGrid: [],
      maxRows: 0,
      cols: [],
      colsGrid: [],
      maxCols: 0,
      total: 0,
      grid: [],
      notFinal: true,
      attempsLeft: 0,
      arrR: [],
      arrC: [],
      rowErrors: [],
      colErrors: [],
      totalTips: 0,
      tips: [],
      enableErrors: false,
      contentLoading: true,
      loadLevel: false,
      reset: false,
      tapActive: false,
      checkModal: false,
      checkBtn: false,
      answers: [],
      resultItems: [],
      currentWord: [],
      activeCell: null,
      userLayout: [],
      failModal: false,
      winModal: false,
      prizes: null,
      tipsLayout: [],
      newTipsLayout: [],
      gameLoad: false,
      checkGameProgress: false,
    };
  },
  async created() {
    this.gameType = this.$route.meta.gameType;
    // this.gameData = await this.sudokuGame();
    if (this.games.nonogram.content.layout.length == 0) {
      let response = await this.nonogramGameContinue();
      if (response.redirect) {
        window.location.href = (response.redirect);
      } else {
        this.gameData = response;
      }
    }
  },
  computed: {
    ...mapState(useUserStore, ['userInfo', 'games', 'sudokuErrors']),
    gameGrid() {
      let temp = localStorage.getItem('nonogramCurrentState') ? localStorage.getItem('nonogramCurrentState') : null;


      this.grid = [];
      this.tstate = [];
      this.total = 0;
      this.gridArr = JSON.parse(temp) ? JSON.parse(temp) : [];
      this.gridArr = this.gridArr.length ? this.gridArr : [];
      this.gridSize = this.games.nonogram.content.size ? this.games.nonogram.content.size : this.gridSize;
      this.gridWidth = this.games.nonogram.content.width ? this.games.nonogram.content.width : this.gridWidth;
      this.gridHeight = this.games.nonogram.content.height ? this.games.nonogram.content.height : this.gridHeight;
      this.gridSizeBlock = this.gridWidth * this.gridHeight;

      if (this.games.nonogram.state){
        this.newTipsLayout = this.games.nonogram.state.tips ? this.games.nonogram.state.tips : []
        this.attempsLeft = this.games.nonogram.state.possible_errors - this.games.nonogram.state.current_errors;
      }


      if (this.games.nonogram.content.layout[0]){
        this.arrR = [];
        this.arrC = [];
        this.rows = [];
        this.cols = [];

        Object.keys(this.games.nonogram.content.layout[1]).forEach(key => {
          this.arrR.push(this.games.nonogram.content.layout[1][key].length)
        })
        Object.keys(this.games.nonogram.content.layout[0]).forEach(key => {
          this.arrC.push(this.games.nonogram.content.layout[0][key].length)
        })
        this.maxRows = Math.max(...this.arrR)
        this.maxCols = Math.max(...this.arrC)
        this.gridSizeSpacer = this.maxRows * this.maxCols;

        this.sidebarWidth = (100 * this.maxRows) /  (this.maxRows + this.gridWidth);


        Object.keys(this.games.nonogram.content.layout[1]).forEach(key => {
          let arr = [];
          Object.keys(this.games.nonogram.content.layout[1][key]).forEach(i => {
            arr.push(this.games.nonogram.content.layout[1][key][i])
            this.total+=parseInt(this.games.nonogram.content.layout[1][key][i])
          })

          let l = arr.length;
          if ( (this.maxRows - l) > 0){
            for (let i=0; i<(this.maxRows - l); i++){
              arr.unshift(0)
            }
          }
          this.rows.push(arr)
        })

        Object.keys(this.games.nonogram.content.layout[0]).forEach(key => {
          let arr = [];
          Object.keys(this.games.nonogram.content.layout[0][key]).forEach(i => {
            arr.push(this.games.nonogram.content.layout[0][key][i])
          })
          let l = arr.length;
          if ( (this.maxCols - l) > 0){
            for (let i=0; i<(this.maxCols - l); i++){
              arr.unshift(0)
            }
          }
          // arr = arr.reverse();
          this.cols.push(arr)
        })
      }

      for (var i = 0; i < this.gridSizeBlock; i++) {
        let el = {};
        let row  = Math.ceil((i+1) / this.gridHeight);
        let col = parseInt( ((i/this.gridWidth + 1) - row)*(this.gridWidth + 1) + 1 );
        el.row = row;
        el.col = col;
        // el.highlighted = false;
        // el.key  = 0;
        el.value = this.gridArr[i] ? parseInt(this.gridArr[i]) : 0;
        el.index = i;
        // el.locked = this.gridArr[i] != 0 ? true : false;
        // if (temp && this.gridArr[i]!=this.games.sudoku.content.layout[i]){
        //   el.locked = false;
        // }
        this.grid.push(el);

      }
      this.totalTips = 0;
      if(this.newTipsLayout.length){
        Object.keys(this.newTipsLayout).forEach(key => {

          if (this.newTipsLayout[key].t === 22){
            Object.keys(this.grid).forEach(j => {
              if (this.grid[j].row === this.newTipsLayout[key].data.row && this.grid[j].col === this.newTipsLayout[key].data.column){
                this.grid[j].tipsCell = true
                this.grid[j].locked = true;
                this.grid[j].value = 1;
                this.totalTips++;
              }
            })
          }

          if (this.newTipsLayout[key].t === 23){
            Object.keys(this.newTipsLayout[key].data).forEach(i => {
              Object.keys(this.grid).forEach(j => {
                if (this.grid[j].row === this.newTipsLayout[key].data[i].row && this.grid[j].col === this.newTipsLayout[key].data[i].column){
                  this.grid[j].tipsCell = true
                  this.grid[j].locked = true;
                  this.grid[j].value = 1;
                  this.totalTips++;
                }
              })
            })
          }

            if (this.newTipsLayout[key].t === 24){
              this.enableErrors = true;
            }

        })
      }

      if (this.games.nonogram.state){
        if (this.games.nonogram.state.tips){
          Object.keys(this.games.nonogram.state.tips).forEach(key => {
            if (this.games.nonogram.state.tips[key].data.seconds_before_expiration){
              Object.keys(this.tips).forEach(i => {
                if (this.tips[i]){
                  if (this.tips[i].id == this.games.nonogram.state.tips[key].t){
                    this.tips[i].seconds_before_expiration = this.games.nonogram.state.tips[key].data.seconds_before_expiration
                  }
                }
              })
            }

          })
        }
      }

      this.getTotal();

      if (this.totalValues > 0 && this.totalValues > this.totalTips){
        this.checkBtn = true;
        this.reset = true
      }
      else{
        this.reset = false
        this.checkBtn = false;
      }


      if (this.totalValues){


        if ((this.total - this.totalValues) <= 1)
          this.disableTip(22,this.gameType);
        else
          this.enableTip(22,this.gameType)

        if ((this.total - this.totalValues) <= 5)
          this.disableTip(23,this.gameType);
        else
          this.enableTip(23,this.gameType);
      }

      let rowsArr = [];
      this.rowsGrid = [];
      let rowsLocal = [];
      if (localStorage.getItem('rows'))
        rowsLocal = JSON.parse(localStorage.getItem('rows'))

      Object.keys(this.rows).forEach(key => {
        let rowsValues = [],
            rowNum=0;
        let rowsGridLine = [];
        Object.keys(this.rows[key]).forEach(i => {
          let el = {};
          el.text = this.rows[key][i]!=0 ? this.rows[key][i] : '';
          el.checked = false;
          if(rowsLocal.length && rowsLocal[key][i] == 1)
              el.checked = true;
          rowsGridLine.push(el);
        })
        this.rowsGrid.push(rowsGridLine)
        rowsGridLine = [];

        Object.keys(this.grid).forEach(j => {

          if (this.grid[j].row == parseInt(key)+1){
            if (this.grid[j].value === 1){
              rowNum++
            }
            else{
              if (rowNum > 0)
                rowsValues.push(rowNum)
              rowNum = 0
            }
          }

          if (this.grid[j].index===(this.gridSizeBlock - 1)){
            if (rowNum > 0)
              rowsValues.push(rowNum)
          }
        })

        rowsArr.push(rowsValues)
      });


      let colsArr = [];
      this.colsGrid = [];
      let colsLocal = [];
      if (localStorage.getItem('cols'))
        colsLocal = JSON.parse(localStorage.getItem('cols'))

      Object.keys(this.cols).forEach(key => {
        let colsValues = [],
            colNum=0;

        let colsGridLine = [];
        Object.keys(this.cols[key]).forEach(i => {
          let el = {};
          el.text = this.cols[key][i]!=0 ? this.cols[key][i] : '';
          el.checked = false;
          if(colsLocal.length && colsLocal[key][i] == 1)
            el.checked = true;
          colsGridLine.push(el);
        })
        this.colsGrid.push(colsGridLine)
        colsGridLine = [];

        Object.keys(this.grid).forEach(j => {

          if (this.grid[j].col == parseInt(key)+1){
            if (this.grid[j].value === 1){
              colNum++
            }
            else{
                if (colNum > 0)
                  colsValues.push(colNum)
                colNum = 0
            }
          }

          if (this.grid[j].index===(this.gridSizeBlock - 1)){
            if (colNum > 0)
              colsValues.push(colNum)
          }
        })

        colsArr.push(colsValues)
      });

      this.rowErrors = [];
      Object.keys(rowsArr).forEach(key => {

        let rowTemp = [];
        Object.keys(this.rows[key]).forEach(j => {
          if (this.rows[key][j]!=0)
            rowTemp.push(this.rows[key][j])
        })

       if (rowTemp.length === rowsArr[key].length){
         if (JSON.stringify(rowTemp) === JSON.stringify(rowsArr[key])) {

          }
          else{
            let tempSum = 0, sum = 0;
            Object.keys(rowTemp).forEach(i => {
              tempSum+=rowTemp[i];
            })
            Object.keys(rowsArr[key]).forEach(i => {
              sum+=rowsArr[key][i]
            })

           if(sum >= tempSum)
             this.rowErrors.push(key)

         }
       }

      Object.keys(rowTemp).forEach(i => {
        if(rowsArr[key][i] && rowsArr[key][i] > rowTemp[i]){
          this.rowErrors.push(key)
        }
      })

      if (rowsArr[key].length > rowTemp.length){
        this.rowErrors.push(key)
      }


      })

      this.colErrors = [];
      Object.keys(colsArr).forEach(key => {

        let colTemp = [];
        Object.keys(this.cols[key]).forEach(j => {
          if (this.cols[key][j]!=0)
            colTemp.push(this.cols[key][j])
        })

        if (colTemp.length === colsArr[key].length){
          if (JSON.stringify(colTemp) === JSON.stringify(colsArr[key])) {

          }
          else{
            let tempSum = 0, sum = 0;
            Object.keys(colTemp).forEach(i => {
              tempSum+=colTemp[i]
            })
            Object.keys(colsArr[key]).forEach(i => {
              sum+=colsArr[key][i]
            })

            if(sum >= tempSum)
              this.colErrors.push(key)

          }
        }

        Object.keys(colTemp).forEach(i => {
          if(colsArr[key][i] && colsArr[key][i] > colTemp[i]){
            this.colErrors.push(key)
          }
        })

        if (colsArr[key].length > colTemp.length){
          this.colErrors.push(key)
        }


      })



      if (this.enableErrors){
        Object.keys(this.grid).forEach(key => {
          if (JSON.stringify(this.rowErrors).includes(this.grid[key].row-1) || JSON.stringify(this.colErrors).includes(this.grid[key].col-1)){
            this.grid[key].error = true;
          }
        })
      }

      this.contentLoading = false;
      this.arrTemp = this.grid;
      return this.grid;
    },
    gameInfo() {
      if (this.games.nonogram)
        return this.games.nonogram
    },
    tipsArr(){
      this.tips = this.games.nonogram.tips
      return this.tips
    }
  },
  methods: {
    ...mapActions(useUserStore, ['sudokuGame', 'checkGame', 'getUserInfo', 'nonogramGameContinue', 'buyTip', 'tipErrors', 'disableTip', 'enableTip', 'levelTitle', 'nonogramGame']),
    toMainPage() {
      setTimeout(() => {
        this.$router.push({name: `home`});
      }, 100);
      this.winModal = false;
      this.failModal = false;   
    },
    toChooseLevel() {
      setTimeout(() => {
        this.$router.push({name: `Nonogram`});
      }, 100);
      this.winModal = false;
      this.failModal = false; 
    },
    gameReset (){
      this.grid = []
      localStorage.removeItem("nonogramCurrentState");
      localStorage.removeItem('rows');
      localStorage.removeItem('cols');
    },
    getTotal (){
      this.totalValues = 0;
      this.totalStr = '';
      Object.keys(this.grid).forEach(key => {
        if (this.grid[key].value === 1){
          this.totalStr+=1;
          this.totalValues+=1;
        }
        else if (this.grid[key].value === 2)
          this.totalStr+=2;
        else
          this.totalStr+=0;
      })
      localStorage.setItem('nonogramCurrentState', JSON.stringify(this.totalStr));
    },
    checkNUmCell(type, row, ind, el){
      let arr = [];
      let data = type == 'rows' ? this.rowsGrid : this.colsGrid;
      el.checked = !el.checked

      Object.keys(data).forEach(key => {
        let line = []
        Object.keys(data[key]).forEach(i => {
          let val = data[key][i].checked ? 1 : 0;
          line.push(val)
        })
        arr.push(line)
      })
      localStorage.setItem(type, JSON.stringify(arr));
    },
    panWidth(){
      let w = 0;
      if(document.querySelector('.game-detail'))
        w = document.querySelector('.game-detail').offsetWidth

      return w;
    },
    panHeight(){
      let h = 0;
      if(document.querySelector('.nonogram-container'))
        h = document.querySelector('body').offsetHeight - 300

      return h;
    },
    containerWidth(){
      let w = 0;
        w = (this.gridWidth + this.maxRows + 2)*24

      if (w>0)
        return w;
    },
    containerHeight(){
      let h = 0;
        h = (this.gridHeight + this.maxCols + 2)*24

      if (h>0)
        return h;
    },
    cellWidth(){
      let w = 0;
      if(document.querySelector('.game-detail')){
        w = document.querySelector('.game-detail').offsetWidth / (this.gridWidth + this.maxRows) - 1
      }

      if (w>0)
        return w;
    },
    async clickCell(el){

      if(!el.locked){
        if (el.value === 0)
          el.value = 2;
        else if (el.value === 2)
          el.value = 1;
        else
          el.value = 0;

       this.getTotal();

        // if (this.totalValues >= this.total){
          // this.checkModal = true;
        // }

      }

    },
    async check(tip){
      if (this.checkGameProgress) {
        return
      }
      this.checkGameProgress = true;
      let response = await this.checkGame({
        gameType: this.gameType,
        params: {
          answer: this.totalStr,
          isFinal: true,
          // forceWin: 1
        }
      });

      if (!response.result){
        this.notFinal = true;
        this.failModal = true;
        this.attempsLeft = response.state.possibleErrors - response.state.currentErrors;
      }

      if (response.result == 'fail'){
        this.failModal = true;
        this.notFinal = false;
      }

      if (response.result == 'win'){
        this.winModal = true;
        if (response.points > 0 || response.puzzleQty > 0 || response.diamonds > 0){
          this.prizes = {};
          this.prizes.points = response.points;
          this.prizes.puzzle = response.puzzleQty;
          this.prizes.diamonds = response.diamonds;
          this.notFinal = false;
        }

      }

      if(response)
        this.contentLoading = false;

      this.checkGameProgress = false;
    },
    async buy(tip){
      this.getTotal();
      this.contentLoading = true;
      // this.saveState()
      // this.check(false);
      // let temp = localStorage.getItem('nonogramCurrentState') ? localStorage.getItem('nonogramCurrentState') : this.tempString;
      const response = await this.buyTip({
        'tip': tip.id,
        'answer': this.totalStr,
      }, this.gameType);
      if (response){
        this.newTipsLayout = response.tips_state;
      }
      this.contentLoading = false;
    },
    async updateUser() {
      await this.getUserInfo();
    },
    onEvent(eName, e){

    },
    nextlevel(){
      if (!this.notFinal){
        this.getUserInfo();
        this.loadLevel = true;
        this.failModal = false;
        this.winModal = false;
        if (this.userInfo.games[this.gameType].statsByLevels.month_levels_balance <= 0) {
          this.$router.push({path:"/nonogram"});
        } else {
          this.startGame();
        }
      }
      else
        this.failModal = false;
    },
    async startGame(){
      if (this.gameLoad) {
        return false;
      }

      this.gameLoad = true;
      let response = await this.nonogramGame( {
        'level': this.userInfo.nonogramLevel
      });
      this.gameReset();
      if (response){
        if (response.redirect)
          window.location.href = (response.redirect);
        else 
          this.$router.go(0);
      } else {
        this.$router.push({path:"/nonogram"});
      }  
    },
    closeModal(){
      this.checkModal = false;
      this.failModal = false;
    },
    confirmClose() {
      this.updateUser();
      this.failModal = false;
      if (this.winModal)
        this.$router.push(`/${this.gameType}`)
    },
  },
  mounted() {
    this.getUserInfo();
    const elem = document.getElementById('panzoom-element');
    const panzoom = Panzoom(elem, {
      maxScale: 2,
      minScale: 1,
      contain: 'outside',
      // pinchAndPan: true,
      // panOnlyWhenZoomed: true
    })
    elem.parentElement.addEventListener('wheel', panzoom.zoomWithWheel)
  },
  beforeRouteLeave(to, from) {
    this.getUserInfo()
  },
};
</script>
