<template>
    <li class="profile-static-card">
        <!-- <img class="profile-static-img" :src="getImageUrl(gameInfo.img)" alt="" width="60" height="77"> -->
        <div :class="['profile-static-img', gameInfo.img]"></div>
        <div>
          <p class="profile-static-title ">{{gameInfo.name}}</p>
          <!-- <div class="profile-static-info">
              <span>Партий сыграно:</span>
              <span class="profile-static-num">{{gameInfo.completed}}</span>
          </div> -->
          <div class="profile-static-info">
              <span>Отгадано: </span>
              <span class="profile-static-num">{{gameInfo.win}}</span>
          </div>
        </div>
    </li>
</template>
  
  
  <script>

  export default {
    name: 'staticCard',
    data() {
      return {
        publicPath: process.env.BASE_URL
      }
    },
    props: {
      gameInfo: {
        type: Object,
        default: null,
      },
    },
  };
  </script>
  