<template>
  <div class="page-wrap" id="pageWrap" v-body-scroll-lock="true" :class="{active: dataLoaded}">
    <Transition name="slide-fade">
      <AppHeader v-if="!$route.meta.errorPage && !$route.meta.hideHeader" />
    </Transition>

    <router-view v-slot="{ Component, route }">
      <transition :name="route.meta.transition || 'fade'">
        <component :is="Component" />
      </transition>
    </router-view>

    <Transition name="slide-fade">
      <Nav v-if="!$route.meta.hideNavigation && !$route.meta.errorPage" />
    </Transition>

    <ModalsContainer />


    <Modal
        v-model="userInfo.bonus_given"
        @close="dailyClose"
        @confirm="dailyClose"
        :title="$t('common.dailyReward.congrats')"
        :btnText="$t('common.dailyReward.take')"
    >

      <div class="buy-popup-text" v-html='$t("common.dailyReward.text")'></div>
      <div class="buy-popup-reward">
        <div class="buy-popup-reward-title">{{ $t('common.crystals', 1) }}</div>
        <div class="buy-popup-reward-img">
          <img src="@/assets/img/crystall-popup.svg" alt="" />
        </div>
      </div>

    </Modal>
    <Modal
        v-model="announceModal"
        @close="modalClose"
        class="modal-wrapper info-modal"
    >
      <template #customHeader>
        <div class="info-modal-img"></div>
      </template>

      <div class="info-modal-content">
          <p>Пройдите как можно больше уровней и соберите все уникальные элементы пазла в течение месяца. Войдите в первую десятку собравших пазл и получите стакан из лимитированной коллекции билайна!</p>
      </div>

    </Modal>


    <AppFooter />
  </div>

</template>

<script>

  import { RouterLink, RouterView } from 'vue-router'
  import { ModalsContainer } from 'vue-final-modal'
  import { useMainStore } from '@/store';
  import { useAuthStore } from '@/store/auth';
  import { useUserStore } from '@/store/user';
  import { mapActions, mapState } from 'pinia';
  import Cookies from 'js-cookie';
  import Nav from '@/components/Nav.vue'
  import AppFooter from '@/components/AppFooter.vue'
  import AppHeader from '@/components/AppHeader.vue'
  import Modal from '@/components/modals/Modal.vue'

  let sessionCookie;
  if (process.env.VUE_APP_SESSION_COOKIE_VALUE){
    sessionCookie = process.env.VUE_APP_SESSION_COOKIE;
    // document.cookie = `${sessionCookie}=1`;
  }

  // Cookies.set('login-event', 1)

  // if (process.env.VUE_APP_AUTH_COOKIE){
  //   console.log(Cookies.get(process.env.VUE_APP_AUTH_COOKIE))
  //   if (!Cookies.get(process.env.VUE_APP_AUTH_COOKIE)){
  //     console.log(Cookies.get(process.env.VUE_APP_AUTH_COOKIE))
  //     // window.location.href = '/auth'
  //   }
  // }

  export default {
    name: 'LayoutDefault',
    components: {
      Nav,
      AppFooter,
      AppHeader,
      ModalsContainer,
      Modal
    },
    data() {
      return {
        dailyModal: false,
        announceModal: false
      };
    },
    computed: {
      ...mapState(useMainStore, ['modalVisible', 'modal', 'dailyReward']),
      ...mapState(useUserStore, ['userInfo','checkDaily', 'dataLoaded']),
      layout() {
        return this.$route.meta.layout;
      },
      fixed() {
        return this.modalVisible && this.modal.type !== 'media';
      },
    },
    async created() {
      const token = decodeURI(Cookies.get(sessionCookie));
      if(token !=='undefined' || token !=''){
        const result = await this.setToken(token);
        if (result) {
          await this.loadData();
          this.dailyModal = this.checkDaily
        }
      }
      // this.dailyModal = this.daily;
    },
    methods: {
      ...mapActions(useAuthStore, ['setToken', 'loadData']),
      ...mapActions(useUserStore, ['takeDaily', 'getUserInfo']),
      async dailyClose() {
        await this.takeDaily();
        this.announceModal = true;
      },
      modalClose(){
        this.announceModal = false;
      }
    },
    beforeRouteLeave(to, from) {
      document.querySelector('.page-wrap').classList.add('loaded')
    },
  };
</script>
