import { defineStore } from 'pinia';
import Http from '@/Http';

const progressDelays = ['2000', '5000', '10000', '15000', '20000', '25000', '600000'];
let progressCounter = -1, progressTimneout;
const staticPrefix = process.env.VUE_APP_URL;
const authSuff = process.env.VUE_APP_AUTH_URL_PROFILE ? process.env.VUE_APP_AUTH_URL_PROFILE : '';

export const useUserStore = defineStore('user', {
  state: () => ({
    dataLoaded: false,
    userInfo: {
      bonus_given: false,
      phone: '+7 999 999-99-99',
      diamonds: '0',
      points: '0',
      games: {
        sudoku: {
          qtyCompleted: 0,
          qtySuccess: 0
        },
        fillword: {
          qtyCompleted: 0,
          qtySuccess: 0
        },
        nonogram: {
          qtyCompleted: 0,
          qtySuccess: 0
        }
      },
      fill: {
        layout: 'штанакпааиамгрль',
        size: 4
      },
      puzzle:
        {
          openedItems: [],
          qty: 25
        },
      sudokuLevel: 'low',
      fillwordLevel: 'low',
      nonogramLevel: 'low',
    },
    gamesLevels:{
      sudokuLevel: 'low',
      fillwordLevel: 'low',
      nonogramLevel: 'low',
    },
    staticPrefix: process.env.VUE_APP_URL,
    sudokuErrors: false,
    promo: [],
    shop: [],
    games: {
      sudoku: {
        content: {
          layout: []
        },
        tips: []
      },
      fillword: {
        content: {
          layout: []
        },
        tips: []
      },
      nonogram: {
        content: {
          layout: []
        },
        tips: []
      }
    },
    phone: '+7 999 999-99-99',
    puzzle: {
      total: 25,
      grid: [2,6,10],
      completed: false,
    },
    storeBackUrl: '',
    serviceMode: false,
    winners: {}
  }),
  getters: {
    // sudokuData: (state) => sudokuGamestate.games.sudoku,
  },
  actions: {
    changeLevel(gamelevel, val) {
      this.gamesLevels[gamelevel] = val;
    },
    levelTitle(level) {
      if (level === 'low')
        return 'Легко'
      if (level === 'middle')
        return 'Средне'
      if (level === 'high')
        return 'Сложно'
      if (level === 'ultra')
        return 'Экстремально'
    },
    checkDaily() {
      return this.userInfo.bonus_given
    },
    async takeDaily() {
      this.userInfo.bonus_given = false;
    },
    setBackUrl(url) {
      this.storeBackUrl = url;
    },
    tipErrors(val) {
      this.sudokuErrors = val;
    },
    disableTip(val, gameType) {
      this.games[gameType].tips.forEach((el) => {
        if (el.id == val)
          el.status = 0
      });
      // if (this.games.sudoku.tips)
      //   this.games.sudoku.tips[val].status = 0;
    },
    enableTip(val, gameType) {
      this.games[gameType].tips.forEach((el) => {
        if (el.id == val)
          el.status = 1
      });
      // if (this.games.sudoku.tips)
      //   this.games.sudoku.tips[val].status = 0;
    },
    async getUserInfo() {
      const result = await Http.get('/profile');
      if (result) {
        this.dataLoaded = true;
        this.userInfo = result;
        this.userInfo.sudokuLevel = this.userInfo.games.sudoku.levelName ? this.userInfo.games.sudoku.levelName  : 'low';
        this.userInfo.fillwordLevel = this.userInfo.games.fillword.levelName  ? this.userInfo.games.fillword.levelName  : 'low';
        this.userInfo.nonogramLevel = this.userInfo.games.nonogram.levelName  ? this.userInfo.games.nonogram.levelName  : 'low';
        this.gamesLevels.sudokuLevel = this.userInfo.games.sudoku.levelName  ? this.userInfo.games.sudoku.levelName  : 'low';
        this.gamesLevels.fillwordLevel = this.userInfo.games.fillword.levelName  ? this.userInfo.games.fillword.levelName  : 'low';
        this.gamesLevels.nonogramLevel = this.userInfo.games.nonogram.levelName  ? this.userInfo.games.nonogram.levelName  : 'low';
      }
    },
    async checkProgress(data) {
      if (progressCounter < progressDelays.length){
        // clearTimeout(progressTimneout);
        const result = await Http.post('/store-check', data);
        if (result.data.inProgress) {
          progressCounter++;
          await new Promise(resolve => setTimeout(resolve, progressDelays[progressCounter]));
          await this.checkProgress(data);
        }
        else if(result.data.isSuccess){
          this.userInfo.diamonds = result.data.userDiamonds;
        }
      }
    },
    async sudokuGame(data) {
      const result = await Http.get(`/game/sudoku/start-new?level=${this.gamesLevels.sudokuLevel}`, data);
      if (result && !result.redirect) {
        this.games.sudoku = result;
      }
      return result;
    },
    async fillwordGame(data) {
      const result = await Http.get(`/game/fillword/start-new?level=${this.gamesLevels.fillwordLevel}`, data);
      if  (result && !result.redirect) {
        this.games.fillword = result;
      }
      return result;
    },
    async nonogramGame(data) {
      const result = await Http.get(`/game/nonogram/start-new?level=${this.gamesLevels.nonogramLevel}`, data);
      if  (result && !result.redirect) {
        this.games.nonogram = result;
      }
      return result;
    },
    async sudokuGameContinue() {
      const result = await Http.get('/game/sudoku/start');
      if (result && !result.redirect) {
        this.games.sudoku = result;
      }
      return result;
    },
    async getWinners() {
      const result = await Http.get('/winners');
      if (result) {
        this.winners = result;
      }
      return result;
    },
    async fillwordGameContinue() {
      const result = await Http.get('/game/fillword/start');
      if (result && !result.redirect) {
        this.games.fillword = result;
      }
      return result;
    },
    async nonogramGameContinue() {
      const result = await Http.get('/game/nonogram/start');
      if (result && !result.redirect) {
        this.games.nonogram = result;
      }
      return result;
    },
    async checkGame(data) {
      const result = await Http.post(`/game/${data.gameType}/check`, data.params);
      if (result) {
        return result;
      }
    },
    async getShop() {
      const result = await Http.get('/store');
      if (result) {
        this.shop=result;
      }
    },
    async buyDiamods(data) {
      const result = await Http.post('/store', data);
      if (result) {
        if (result.data.isSuccess)
          this.userInfo.diamonds = result.data.userDiamonds
          this.userInfo.points = result.data.userPoints

        return result
      }
    },
    async buyTip(data, gameType) {
      const result = await Http.post(`/game/${gameType}/tip`, data);
      if (result) {
        if (result.userDiamonds)
          this.userInfo.diamonds = result.userDiamonds;

        this.games[gameType].tips.forEach((el) => {
            el.status = result.tips[el.id]
        });
        if (result.tips['21']==-2)
          this.sudokuErrors = true;

        if(result.layout)
          this.games[gameType].state.tips_layout = result.layout
        if(result.tips_state)
          this.games[gameType].state.tips = result.tips_state

        return result;
      }
    },
    disableSudokuErrors() {
      this.sudokuErrors = false;
    },
    sudokuGrid() {

      this.grid = [];
      let arr = this.games.sudoku.content.layout;

      for (var i = 0; i < arr.length; i++) {
        let el = {};
        let row  = Math.ceil((i+1) / 9);
        let col = parseInt( ((i/9 + 1) - row)*10 + 1 );
        el.row = row;
        el.col = col;
        el.value = arr[i] != 0 ? arr[i] : '';
        el.index = i;
        el.locked = arr[i] != 0 ? true : false;

        this.grid.push(el);
      }


      return this.grid;
    },
  },
});
