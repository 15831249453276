<template>
  <div class="page-container">
    <div class="page-title">Филворды</div>
    <div class="page-inner game-page-inner game-detail" :class="[{'content-loading' : contentLoading}, {'loadLevel' : loadLevel}]">
      <div class="game-detail-top">
        <router-link class="go-back" to="/fillword">{{ $t("nav.back") }}</router-link>
        <div class="game-difficulty-title">
          Уровень №{{ gameInfo.num }} 
          <div class="topic" v-if="games.fillword.content && games.fillword.content.topic">"{{ games.fillword.content.topic }}"</div>
        </div>
      </div>

      <div class="fillword-grid"  v-bind:style="{ gridTemplateColumns: `repeat(${gridSize}, 1fr)`, }" @touchmove="onMoveItem" v-touch:hold="onTapItem" @mousedown="onTapItem" v-touch:release="offTapItem" v-touch-options="{touchHoldTolerance: 10, dragFrequency: 10}" v-touch:drag="onDragItem">
        <cell v-for="item in gameGrid" :item="item" @click="item.highlighted = true"></cell>
      </div>

      <tips :tips="tipsArr" @buy="buy" :loading="contentLoading"></tips>

      <Modal
          v-model="winModal"
          :title="'Поздравляем!'"
          @close="confirmClose"
          class="modal-wrapper game-win-modal"
      >
        <div class="buy-popup-text">
          Вы прошли уровень №{{ gameInfo.num }}<br>
          Ваши призы:
        </div>
        <div class="game-rewards" v-if="prizes">
          <div class="reward points" v-if="prizes.points">+{{prizes.points}}</div>
          <div class="reward diamonds" v-if="prizes.diamonds">+{{prizes.diamonds}}</div>
          <div class="reward puzzle" v-if="prizes.puzzle">+{{prizes.puzzle}}</div>
        </div>

        <template #customFooter>
          <div class="modal-buttons-large mt-0">
            <div @click="toMainPage" class="btn btn-secondary">Главное меню</div>
            <div class="btn btn-primary" @click="nextlevel()">Следующий уровень</div>
            <!--            <button @click="startNew" tabindex="-1" class="btn btn-primary">Следующий уровень</button>-->
          </div>
        </template>

      </Modal>

      <Modal
          v-model="failModal"
          :title="'Неудача!'"
          @close="confirmClose"
      >
        <div class="buy-popup-text">
          Вы не заполнили филворд, попробуйте ещё раз
        </div>
        <div class="buy-popup-reward">
          <img src="@/assets/img/lose.png" class="game-fail-img" alt="fail" />
        </div>

        <template #customFooter>
          <div class="modal-buttons-large mt-0">
            <div @click="toMainPage" class="btn btn-secondary">Главное меню</div>
            <!--            <button @click="startNew" tabindex="-1" class="btn btn-primary">Следующий уровень</button>-->
            <div :to="`/${gameType}`" @click="nextlevel()" class="btn btn-primary">Следующий уровень</div>
          </div>
        </template>

      </Modal>

    </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';
import cell from '@/components/games/fillword/cell.vue'
import tips from '@/components/games/tips.vue'
import Modal from '@/components/modals/Modal.vue'
import promoItem from '@/components/promo/promoItem.vue';

export default {
  name: 'FillwordGame',
  components: {
    RouterLink,
    RouterView,
    cell,
    tips,
    Modal,
    promoItem
  },
  data() {
    return {
      gridSize: 3,
      grid: [],
      contentLoading: true,
      reset: false,
      tapActive: false,
      answers: [],
      resultItems: [],
      currentWord: [],
      activeCell: null,
      userLayout: [],
      failModal: false,
      winModal: false,
      loadLevel: false,
      prizes: null,
      tipsLayout: [],
      debugInfo: 1,
      newTipsLayout: [],
      gameLoad: false,
      checkGameProgress: false,
    };
  },
  async created() {
    this.gameType = this.$route.meta.gameType;
    if (this.games.fillword.content.layout.length == 0) {
      let response = await this.fillwordGameContinue();
      if (response.redirect) {
        window.location.href = (response.redirect);
      } else {
        this.gameData = response;
      }
    }
  },
  async mounted() {
    this.getUserInfo();
  },
  computed: {
    ...mapState(useUserStore, ['userInfo', 'games', 'sudokuErrors', 'gamesLevels']),
    gameGrid() {
      let temp = localStorage.getItem('fillwordCurrentState') ? localStorage.getItem('fillwordCurrentState') : null;


      this.grid = [];
      this.tstate = [];
      this.gridArr = [];
      this.gridArr = this.gridArr.length ? this.gridArr : this.games.fillword.content.layout;
      this.gridSize = this.games.fillword.content.size ? this.games.fillword.content.size : this.gridSize;
      this.gridSizeBlock = this.gridSize*this.gridSize;

      if (this.games.fillword.state)
        this.newTipsLayout = this.games.fillword.state.tips ? this.games.fillword.state.tips : []

      for (var i = 0; i < this.gridArr.length; i++) {
        let el = {};
        let row  = Math.ceil((i+1) / this.gridSize);
        let col = parseInt( ((i/this.gridSize + 1) - row)*(this.gridSize + 1) + 1 );
        el.row = row;
        el.highlighted = false;
        el.col = col;
        el.key = 0;
        el.value = this.gridArr[i] != 0 ? this.gridArr[i] : '';
        el.index = i;
        // el.locked = this.gridArr[i] != 0 ? true : false;
        // if (temp && this.gridArr[i]!=this.games.sudoku.content.layout[i]){
        //   el.locked = false;
        // }
        this.grid.push(el);

      }

      if (temp && JSON.parse(temp).length > 0){
        this.resultItems = JSON.parse(temp)
      }


      if (this.games.fillword.content.answers){
        if ((this.games.fillword.content.answers.length - this.resultItems.length) <= 1){
          this.disableTip(17,this.gameType);
          // this.disableTip(16,this.gameType);
          this.disableTip(18,this.gameType);
        }
      }

      if (this.resultItems.length){

          Object.keys(this.resultItems).forEach(key => {
            if (this.games.fillword.content.answers){
            Object.keys(this.games.fillword.content.answers).forEach(j => {
              if (JSON.stringify(this.games.fillword.content.answers[j]) === JSON.stringify(this.resultItems[key]) ){
                Object.keys(this.resultItems[key]).forEach(i => {
                  this.grid[this.resultItems[key][i]].num = parseInt(j)+1;
                  this.grid[this.resultItems[key][i]].locked = true;
                })
              }
            })
            }
          })
      }

      if(this.newTipsLayout.length){
        Object.keys(this.newTipsLayout).forEach(key => {
          if (this.newTipsLayout[key].t === 16){
            Object.keys(this.games.fillword.content.answers).forEach(j => {

              Object.keys(this.games.fillword.content.answers[j]).forEach(i => {
                if (this.games.fillword.content.answers[j][i] === this.newTipsLayout[key].data.number)
                  if (!this.grid[this.newTipsLayout[key].data.number].num)
                    this.grid[this.newTipsLayout[key].data.number].num = parseInt(j)+1;
              })

            })
          }
          if (this.newTipsLayout[key].t === 18){
            Object.keys(this.newTipsLayout[key].data.numbers).forEach(j => {

              Object.keys(this.games.fillword.content.answers).forEach(i => {
                Object.keys(this.games.fillword.content.answers[i]).forEach(k => {

                  if (this.games.fillword.content.answers[i][k] == this.newTipsLayout[key].data.numbers[j] && !this.grid[this.newTipsLayout[key].data.numbers[j]].num){
                    this.grid[this.newTipsLayout[key].data.numbers[j]].num = parseInt(i)+1;
                  }

                })
              })

            })
          }
          if (this.newTipsLayout[key].t === 17){

            Object.keys(this.games.fillword.content.answers).forEach(j => {
              if (JSON.stringify(this.games.fillword.content.answers[j]) === JSON.stringify(this.newTipsLayout[key].data.numbers) ){
                Object.keys(this.games.fillword.content.answers[j]).forEach(i => {
                  if (!this.grid[this.games.fillword.content.answers[j][i]].num){

                    this.grid[this.games.fillword.content.answers[j][i]].num = parseInt(j)+1;
                    this.grid[this.games.fillword.content.answers[j][i]].locked = true;
                  }
                })
              }
            })

            if (!JSON.stringify(this.resultItems).includes(JSON.stringify(this.newTipsLayout[key].data.numbers))){
              this.resultItems.push(this.newTipsLayout[key].data.numbers)
              localStorage.setItem('fillwordCurrentState', JSON.stringify(this.resultItems));
            }


          }
        })
      }

      this.contentLoading = false;
      this.arrTemp = this.grid;
      return this.grid;
    },
    gameInfo() {
      return this.games.fillword
    },
    tipsArr(){
      this.tips = this.games.fillword.tips
      return this.tips
    }
  },
  methods: {
    ...mapActions(useUserStore, ['sudokuGame', 'checkGame', 'getUserInfo', 'fillwordGameContinue', 'buyTip', 'tipErrors', 'disableTip', 'enableTip', 'fillwordGame']),
    onMoveItem(event) {
      event.preventDefault();
      if (this.tapActive && event.changedTouches.length) {
        let el = document.elementFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY)
        if (el && el.classList.contains('fillword-cell'))
          this.checkCell(Array.prototype.indexOf.call(el.parentElement.children, el))
      }
    },
    toMainPage() {
      setTimeout(() => {
        this.$router.push({name: `home`});
      }, 100);
      this.winModal = false;
      this.failModal = false;   
    },
    checkCell(index){
      if (!this.currentWord.includes(this.grid[index].index) && (this.activeCell === null ||
            (( Math.abs(this.activeCell.col - this.grid[index].col) <= 1 )
            && ( Math.abs(this.activeCell.row - this.grid[index].row) <= 1 )
            && ( ( Math.abs(this.activeCell.row - this.grid[index].row) + Math.abs(this.activeCell.col - this.grid[index].col) ) < 2  )
          )))
      {

        this.currentWord.push(this.grid[index].index)
        this.grid[index].highlighted = true;
        this.activeCell = this.grid[index]
      }
      else{
        // this.activeCell = null
      }

    },
    pickCell(index){

    },
    onTapItem(event) {
      this.debugInfo = 'onTapItem' +  this.activeCell;;
      this.currentWord = [];
      Object.keys(this.grid).forEach(key => {
        this.grid[key].highlighted = false;
      })
      this.tapActive = true;

      if (this.tapActive && event.changedTouches) {
        let el = document.elementFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY)
        if (el && el.classList.contains('fillword-cell'))
          this.activeCell = this.grid[Array.prototype.indexOf.call(el.parentElement.children, el)]
      }
      else if (this.tapActive && event.clientX && event.clientY) {
        let el = document.elementFromPoint(event.clientX, event.clientY)
        if (el.classList.contains('fillword-cell'))
          this.activeCell = this.grid[Array.prototype.indexOf.call(el.parentElement.children, el)]

      }

    },
    async offTapItem(event) {
      let totalItems = 0,
          temp = '',
          tempWords = '';
      Object.keys(this.grid).forEach(key => {
        this.grid[key].highlighted = false;
      })

      if (this.currentWord.length > 1){


        Object.keys(this.games.fillword.content.answers).forEach(key => {
          if (JSON.stringify(this.games.fillword.content.answers[key]) == JSON.stringify(this.currentWord)) {
            if (this.resultItems.length > 0){
              if (!JSON.stringify(this.resultItems).includes(JSON.stringify(this.currentWord))) {
                Object.keys(this.games.fillword.content.answers[key]).forEach(i => {
                  if (!this.grid[this.games.fillword.content.answers[key][i]].num)
                    this.grid[this.games.fillword.content.answers[key][i]].num = parseInt(key)+1;

                  this.grid[this.games.fillword.content.answers[key][i]].locked = true;

                })
                this.resultItems.push(this.currentWord);
                localStorage.setItem('fillwordCurrentState', JSON.stringify(this.resultItems));
              }
            }
            else{
              Object.keys(this.games.fillword.content.answers[key]).forEach(i => {
                if (!this.grid[this.games.fillword.content.answers[key][i]].num)
                  this.grid[this.games.fillword.content.answers[key][i]].num = parseInt(key)+1;
                this.grid[this.games.fillword.content.answers[key][i]].locked = true;
              })
              this.resultItems.push(this.currentWord);
              localStorage.setItem('fillwordCurrentState', JSON.stringify(this.resultItems));
            }

          }
        })


      }

      this.currentWord = []
      this.tapActive = false;


      if (this.resultItems.length == this.games.fillword.content.answers.length){
        if (this.checkGameProgress) {
          return
        }
        // let res = JSON.parse(this.resultItems);
        this.checkGameProgress = true;
        let response = await this.checkGame({
          gameType: this.gameType,
          params: {
            answer: JSON.stringify(this.resultItems),
            isFinal: true,
          }
        });

        
        if (response.result == 'fail'){
          this.failModal = true;
        }

        if (response.result == 'win'){
          this.winModal = true;
          if (response.points > 0 || response.puzzleQty > 0 || response.diamonds > 0){
            this.prizes = {};
            this.prizes.points = response.points;
            this.prizes.puzzle = response.puzzleQty;
            this.prizes.diamonds = response.diamonds;
          }
        }

        if(response)
          this.contentLoading = false;

          this.checkGameProgress = true;

      }
    },
    onDragItem(event) {
      if (this.tapActive && event.clientX && event.clientY){
        let el = document.elementFromPoint(event.clientX, event.clientY)
        if (el.classList.contains('fillword-cell'))
          this.checkCell(Array.prototype.indexOf.call(el.parentElement.children, el))
      }
    },
    async buy(tip){
      this.contentLoading = true;
      // this.saveState()
      // this.check(false);
      // let temp = localStorage.getItem('fillwordCurrentState') ? localStorage.getItem('fillwordCurrentState') : this.tempString;
      const response = await this.buyTip({
        'tip': tip.id,
        'answer': JSON.stringify(this.resultItems),
      }, this.gameType);
      if (response){
        this.newTipsLayout = response.tips_state;
      }
      this.contentLoading = false;
    },
    async updateUser() {
      await this.getUserInfo();
    },
    nextlevel(){
      this.loadLevel = true;
      this.getUserInfo();
      this.failModal = false;
      this.winModal = false;
      if (this.userInfo.games[this.gameType].statsByLevels.month_levels_balance <= 0) {
        this.$router.push("/fillword");
      } else {
        this.startGame();
      }
    },
    confirmClose() {
      this.nextlevel();
    },
    async startGame(){
      if (this.gameLoad) {
        return false;
      }

      this.gameLoad = true;
      localStorage.removeItem('fillwordCurrentState');
      let response = await this.fillwordGame( {
        'level': this.userInfo.fillwordLevel
      });
      if (response){
          if (response.redirect)
            window.location.href = (response.redirect);
          else
            this.$router.go(0);
      } else {
        this.$router.push("/fillword");
      }
      this.contentLoading = false;
    },
  },
  beforeRouteLeave(to, from) {
    // this.saveState()
    this.getUserInfo()
    // if (!this.gameFinished)
    //   this.check(false);
  },
};
</script>
