<template>

  <div class="puzzle-progress-container" :class="{ 'completed': puzzle.completed }">
    <div class="puzzle-progress">
      <div class="puzzle-progress-value" :style="{ width: progress + '%' }" ></div>
    </div>
    <div class="gift-icon"></div>
  </div>
</template>


<script>
import { mapState, mapActions } from 'pinia';

export default {
  name: 'puzzleProgress',
  props: {
    puzzle: {
      type: Object,
      default: {}
    },

  },
  components: {

  },
  data() {
    return {
      showModal: false,
      hint: false,
      progressValue: 0,
    };
  },
  computed: {
   progress() {
     if (this.puzzle.openedItems){
       this.progressValue = parseInt( (this.puzzle.openedItems.length / this.puzzle.qty) * 100 )
       this.progressValue = this.puzzle.openedItems.length == this.puzzle.qty ? 100 : this.progressValue;
     }
     return this.progressValue;
   }
  },
  created() {

  },
  methods: {
    toggleHint() {
      this.hint = !this.hint;
    }
  }
};
</script>
