<template>
  <div class="promo-item" v-if="item">
    <div class="promo-item-top" @click="showModal = true" v-if="!gameMode">
      <div class="promo-item-preview" v-html="item.promocode.title"></div>
      <div class="promo-item-logo" v-if="item.partner.logo_url">
        <img :src="getImageUrl(`${item.partner.logo_url}`)" :alt="item.partner.title" />
      </div>
    </div>
    <div v-else class="win-promo-title" @click="showModal = true" v-html="item.promocode.title"></div>
    <div class="promo-item-bottom">
      <Code :code="item.promocode.value" :toastSuccess="$t('common.copySuccess')" v-if="item.promocode.value && !gameMode"></Code>
      <div class="promo-item-link btn btn-medium btn-primary" v-if="gameMode" @click="showModal = true">Инструкция</div>
      <a :href="item.promocode.url" target="_blank" class="promo-item-link btn btn-medium btn-primary">{{ $t("pages.promo.activate") }}</a>
    </div>


    <Modal
        v-model="showModal"
        @close="modalClose"
        class="modal-wrapper promo-modal"
    >
      <template #customHeader>
        <div class="promo-modal-logo" v-if="item.partner.logo_url">
                  <img :src="getImageUrl(item.partner.logo_url)" :alt="item.partner.title" />
        </div>
      </template>

      <div class="promo-text promo-text-large" v-if="item.promocode.description_short" v-html="item.promocode.description_short"></div>
      <div class="promo-text promo-text-large" v-if="item.promocode.description" v-html="item.promocode.description"></div>
      <div class="promo-text" v-if="item.promocode.instruction" v-html="item.promocode.instruction"></div>

      <template #customFooter>
        <div class="promo-item-bottom">
          <Code :code="item.promocode.value" :toastSuccess="$t('common.copySuccess')" v-if="item.promocode.value"></Code>
          <a :href="item.promocode.url" target="_blank" class="promo-item-link btn btn-medium btn-primary">{{ $t("pages.promo.activate") }}</a>
        </div>
      </template>

    </Modal>
  </div>
</template>


<script>
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';
import Modal from '@/components/modals/Modal.vue'
import Code from '@/components/promo/codeBlock.vue'

export default {
  name: 'ShopItem',
  props: {
    item: {
      type: Object,
      default: {}
    },
    gameMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Modal,
    Code
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo']),
  },
  created() {
    this.userPhone = `<strong>${this.userInfo.phone}</strong>`;
    this.price = `<strong>${this.item.price} рублей</strong>`;
  },
  methods: {
    modalClose() {
      this.showModal = false;
    },
    getImageUrl(url) {
      return new URL(url, import.meta.url).href
    }
  }
};
</script>
