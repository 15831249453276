<template>

  <div class="game-difficulty">
    <label class="difficulty-check">
      <input type="radio" v-model="difficulty" value="easy">
      <div class="difficulty-check-label">Легко</div>
    </label>
    <label class="difficulty-check">
      <input type="radio" v-model="difficulty" value="medium">
      <div class="difficulty-check-label">Средне</div>
    </label>
    <label class="difficulty-check">
      <input type="radio" v-model="difficulty" value="hard">
      <div class="difficulty-check-label">Сложно</div>
    </label>
    <label class="difficulty-check">
      <input type="radio" v-model="difficulty"  value="extrahard">
      <div class="difficulty-check-label">Экстремально</div>
    </label>
  </div>

</template>


<script>
import { mapState, mapActions } from 'pinia';

export default {
  name: 'difficulty',
  props: {
    mode: {
      type: String,
      default: 'easy'
    },

  },
  components: {
    difficulty: 'easy'
  },
  data() {
    return {
      showModal: false,
      hint: false,
      progressValue: 0,
    };
  },
  computed: {
    // difficulty () {
    //   return this.mode;
    // }
  },
  created() {

  },
  methods: {

  }
};
</script>
