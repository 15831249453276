<template>
  <div class="page-container">
    <div class="page-title">Филворды</div>
    <div class="page-inner game-page-inner">
      <div class="game-detail-top">
        <router-link class="go-back" to="/">{{ $t("nav.back") }}</router-link>
        <div class="game-difficulty-title">{{ $t("pages.games.difficulty") }}</div>
      </div>

<!--      <difficulty :mode="'easy'"></difficulty>-->
      <div class="game-difficulty">
        <label class="difficulty-check">
          <input type="radio" v-model="gamesLevels.fillwordLevel" value="low" @change="levelChange($event)">
          <div class="difficulty-check-label">Легко
            <div class="difficulty-check-continue" v-if="userInfo.games.fillword.levelName === 'low'">Продолжить</div>
          </div>
        </label>
        <label class="difficulty-check">
          <input type="radio" v-model="gamesLevels.fillwordLevel" value="middle" @change="levelChange($event)">
          <div class="difficulty-check-label">Средне
            <div class="difficulty-check-continue" v-if="userInfo.games.fillword.levelName === 'middle'">Продолжить</div>
          </div>
        </label>
        <label class="difficulty-check">
          <input type="radio" v-model="gamesLevels.fillwordLevel" value="high" @change="levelChange($event)">
          <div class="difficulty-check-label">Сложно
            <div class="difficulty-check-continue" v-if="userInfo.games.fillword.levelName === 'high'">Продолжить</div>
          </div>
        </label>
      </div>

      <div class="game-preview game-grid-item" :class="$route.meta.gameType">
        <div class="game-title">Уровень №
          {{ currentLevel }} 
        </div>  
        <div :class="`game-image-container game-image-container-${gamesLevels.fillwordLevel}`">
        </div>
      </div>

      <div class="game-bottom-buttons">

        <div class="game-bottom-buttons">

          <div class="btn btn-white" @click="startNew()">Новая игра</div>
          <div class="btn btn-primary" @click="continueGame()" v-if="userInfo.games.fillword.levelName === gamesLevels.fillwordLevel" :class="{ disabled : !userInfo.games.fillword.hasActive }">Продолжить</div>

        </div>

      </div>

      <Modal
          v-model="startModal"
          :title="'Уверены, что хотите начать новую игру?'"
          @close="confirmClose"
      >
        <div class="buy-popup-text">
          У вас есть сохраненная игра, которую можно продолжить.
        </div>

        <template #customFooter>
          <div class="modal-buttons-large">
            <div class="btn btn-default" @click="continueGame()" >Продолжить игру</div>
            <div class="btn btn-primary" @click="startGame()">Начать новую игру</div>
          </div>
        </template>

      </Modal>

      <Modal
          v-model="showNoGamesPopUp"
          :title="'Поздравляем!'"
          @close="toMainPage"
      >
        <div class="buy-popup-text">
          Вы играли слишком много, все доступные уровни закончились. Попробуйте другую игру
        </div>
        <div class="buy-popup-reward">
          <img src="@/assets/img/no-games.png" class="no-games-img" alt="fail" />
        </div>

        <template #customFooter>
          <div class="modal-buttons-large">
            <div @click="toMainPage" class="btn btn-primary">На главную</div>
          </div>
          <div class="updates-date">Новые задания будут в следующем месяце</div>
        </template>

      </Modal>
      <completedLevelModal :finishedLevel="finishedLevelCurrent" :level="gamesLevels.fillwordLevel" @closeCompletedLevelModal="closeCompletedLevelModal"></completedLevelModal>
    </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';
import difficulty from '@/components/games/difficulty.vue'
import Modal from '@/components/modals/Modal.vue'
import completedLevelModal from '@/components/modals/completedLevelModal.vue'

export default {
  name: 'Fillword',
  components: {
    RouterLink,
    RouterView,
    difficulty,
    Modal,
    completedLevelModal
  },
  data() {
    return {
      difficulty: 'low',
      noGames: false,
      startModal: false,
      finishedLevelPopUp: true,
      finishedTotalPopUp: false,
      finishedTotalPopUpContinue: false,
      gameLoad: false,
      
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo','fillwordLevel','gamesLevels']),
    currentLevel(){
      let level = 0;
      if (this.userInfo){
          level = this.userInfo.games.fillword.qtyCompleted;
          // if (!this.userInfo.games.fillword.hasActive){
            level +=1
          // }
      }
      return level
    },
    finishedLevelTotal() {
      if (this.userInfo.games.fillword.statsByLevels) {
        const availableLevelTotal = this.userInfo.games.fillword.statsByLevels.available;
        if ((availableLevelTotal<=0 && this.finishedTotalPopUp) || (availableLevelTotal<=1 && this.finishedTotalPopUpContinue)) {
          return true
        }
      } 
      return false;
    },
    finishedLevelCurrent() {
      if (this.userInfo.games.fillword.statsByLevels) {
        const gamesLevel = this.userInfo.games.fillword.statsByLevels[this.gamesLevels.fillwordLevel];
        const availableLevel = this.userInfo.games.fillword.statsByLevels[`total_available_${this.gamesLevels.fillwordLevel}`];
        const availableLevelTotal = this.userInfo.games.fillword.statsByLevels.available;
        if (gamesLevel >= availableLevel && this.finishedLevelPopUp && availableLevelTotal>0) {
          return true;
        };
      } 
      return false;
    },
    showNoGamesPopUp() {
      return this.noGames || this.finishedLevelTotal || this.LevelsBalanceEnd;
    },
    LevelsBalanceEnd() {
      if (this.userInfo.games.fillword.statsByLevels) {
        return !this.userInfo.games.fillword.hasActive && this.userInfo.games.fillword.statsByLevels.month_levels_balance <= 0;
      } 
      return false;
    },
    LevelsBalanceEndIncludeActive() {
      if (this.userInfo.games.fillword.statsByLevels) {
        return this.userInfo.games.fillword.statsByLevels.month_levels_balance <= 0;
      } 
      return true;
    },
  },
  methods: {
    ...mapActions(useUserStore, ['fillwordGame', 'fillwordGameContinue', 'checkGame', 'disableSudokuErrors', 'changeLevel','levelTitle']),
    toMainPage() {
      setTimeout(() => {
        this.$router.push({name: `home`});
      }, 100);
      this.showNoGamesPopUp = false; 
    },
    checkfinishedLevelTotal() {
      const maxAvailable = this.userInfo.games.fillword.hasActive ? 1 : 0;
      return this.userInfo.games.fillword.statsByLevels.available <= maxAvailable;
    },
    checkfinishedLevelCurrent() {      
      const gamesLevel = this.userInfo.games.fillword.statsByLevels[this.gamesLevels.fillwordLevel];
      const availableLevel = this.userInfo.games.fillword.statsByLevels[`total_available_${this.gamesLevels.fillwordLevel}`]
      return gamesLevel >= availableLevel;
    },
    closeCompletedLevelModal() {
      this.finishedLevelPopUp = false;
    },
    levelChange(event){
      this.finishedLevelPopUp = true;
      this.changeLevel('fillwordLevel', event.target.value)
    },
    startNew(){

      if (this.LevelsBalanceEndIncludeActive) {
        this.noGames = true;
      }


      if (this.userInfo.games.fillword.hasActive){
        this.startModal = true;
      }
      else{
        this.startGame();
      }

    },
    async startGame(){
      if (this.gameLoad) {
        return false;
      }

      this.gameLoad = true;

      if (this.checkfinishedLevelTotal()) {
        this.finishedTotalPopUpContinue = true;
        this.startModal = false;
        return;
      }
      
      if (this.checkfinishedLevelCurrent()) {
        this.finishedLevelPopUp  = true;
        this.startModal = false;
        return;
      }
      localStorage.removeItem('fillwordCurrentState');
      let response = await this.fillwordGame({
        'level': this.userInfo.fillwordLevel
      });
      if (response){

       if (response.redirect) {
         window.location.href = (response.redirect);
       } else {
        this.$router.push({path: `/${this.$route.meta.gameType}/start`})
       }
      }
      else{
        this.noGames = true;
      }
    },
    async continueGame(){
      if (this.gameLoad) {
        return false;
      }

      this.gameLoad = true;
      let response = await this.fillwordGameContinue()
      if (response){
        if (response.redirect)
          window.location.href = (response.redirect);
        else
          this.$router.push({path: `/${this.$route.meta.gameType}/start`, params: { new: true } })
      }
      else{
        this.noGames = true;
      }
    },
    confirmClose(){
      this.noGames = false;
      this.finishedTotalPopUp = false;
      this.startModal = false;
      this.finishedTotalPopUpContinue = false;
    }
  }
};
</script>
