<template>
  <div class="page-container">
    <div class="page-title">{{ $t("pages.shop.pageTitle") }}</div>
    <div class="page-inner">
      <div class="game-detail-top" v-if="storeBackUrl">
        <router-link class="go-back" :to="storeBackUrl">Вернуться в игру</router-link>
      </div>

      <div class="shop-container">

        <div class="shop-list-section">
          <shopItem v-for="item in shopListMoney" :item="item"></shopItem>
        </div>

        <div class="shop-list-section">
          <shopItem v-for="item in shopListPoint" :item="item" :star="true"></shopItem>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import shopItem from '@/components/shop/shopItem.vue';
import { useUserStore } from '@/store/user';

export default {
  name: 'ShopView',
  components: {
    shopItem,
  },
  data(){
    return{
      storeList: []
    }
  },
  async created() {
    this.storeList = await this.getShop();
  },
  computed: {
    ...mapState(useUserStore, ['userInfo', 'shop', 'storeBackUrl']),
    shopListMoney() {
      if(this.shop.data){
        return this.shop.data[Object.keys(this.shop.data)[0]];
      }
    },
    shopListPoint() {
      if(this.shop.data){
        return this.shop.data[Object.keys(this.shop.data)[1]];
      }
    }
  },
  methods: {
    ...mapActions(useUserStore, ['getShop']),
  }
};
</script>
