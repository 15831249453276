<template>
  <div class="page-container">
    <div class="page-title page-content-title">Пользовательское соглашение сервиса «Мир кроссвордов»</div>
    <div class="page-inner content-layout">
      <div class="content-layout-top">
        <router-link class="go-back" to="/">{{ $t("nav.back") }}</router-link>
      </div>
      <div class="content-layout-inner ">

        <p><strong>1. Общие положения</strong></p>
        <p>1.1. Организатором сервиса «Мир кроссвордов» (далее – Сервис) является ПАО «ВымпелКом» (Российская Федерация,
          127083, г. Москва, ул. 8 марта, дом 10, строение 14; ИНН 7713076301; ОГРН 1027700166636) (далее –
          Администрация Сервиса).</p>
        <p>1.2. Соглашение регулирует отношения между Администрацией Сервиса и Пользователем Сервиса (далее –
          Пользователь) в связи с использованием Сервиса Пользователем.</p>
        <p>1.3. Соглашение является публичной офертой, приняв которую Пользователь становится пользователем Сервиса.
          Регистрируясь в Сервисе, либо направляя запрос в Сервис, а также осуществляя регистрацию/авторизацию в
          Сервисе, использование Сервиса и/или Сайта Сервиса, Пользователь соглашается с условиями Соглашения, включая
          все приложения к Соглашению, с учетом дополнений и изменений Соглашения, а также дает свое добровольное,
          информированное, сознательное согласие на обработку его персональных данных и иной персональной информации в
          соответствии с Соглашением и Политикой обработки персональных данных в ПАО «ВымпелКом», размещенной и/или
          доступной на <a href="https://moskva.beeline.ru/customers/legal/userdata/">https://moskva.beeline.ru/customers/legal/userdata/</a>.
        </p>
        <p>1.4. Обязательным условием заключения Соглашения является полное и безоговорочное принятие и соблюдение
          Пользователем Политики обработки персональных данных в ПАО «ВымпелКом», размещенной и/или доступной на <a
              href="https://moskva.beeline.ru/customers/legal/userdata/">https://moskva.beeline.ru/customers/legal/userdata/</a>,
          являющейся неотъемлемой частью Соглашения и других ссылающихся на нее документов.</p>
        <p>1.5. В рамках Сервиса Пользователю предоставляется удаленный доступ через сеть Интернет к программам для ЭВМ
          и/или базам данных, реализующим функционал интеллектуальных мобильных игр, по правилам которых участникам
          предлагается собрать пазл, разгадать японский кроссворд и проч. (далее – игры). Правила игр определяются
          Администрацией Сервиса. Игры не являются азартными играми, пари, лотереями или иными основанными на риске
          мероприятиями.</p>

        <p><strong>2. Порядок подключения Сервиса</strong></p>
        <p>2.1. Сервис доступен Пользователям - абонентам оператора связи ПАО «ВымпелКом» (далее также - Оператор) -
          физическим лицам всех регионов РФ, в которых имеется покрытие сети Оператора, обслуживающимся на тарифных
          планах предоплатной и постоплатной системы расчетов, с учетом ограничений, предусмотренных Соглашением.</p>
        <p>Подключение Сервиса осуществляется посредством отправки SMS-сообщения с любым текстом (кроме Стоп/Stop) на
          номер 9303, отправки запроса на подключение Сервиса из мобильного приложения билайн, с сайта beeline.ru,
          включая субдомены или сайта krossvord.beeline.ru, включая субдомены (далее – Сайт Сервиса) или иными
          способами, информацию о которых Администрация Сервиса размещает на страницах сайта beeline.ru или
          krossvord.beeline.ru (включая субдомены), непосредственно в интерфейсах Cервиса, в СМИ и других публичных
          источниках, включая ресурсы в сети Интернет, в адресных информационных сообщениях, направляемых Администрацией
          с использованием собственных технологических средств (SMS, USSD, DSTK, Flash-инфо и др.), или иными способами
          с последующим выполнением инструкций, направляемых в SMS-сообщениях или иными способами.</p>

        <p><strong>3. Стоимость предоставления Сервиса. Внутрисервисные ценности</strong></p>
        <p>3.1. Стоимость предоставления Сервиса в течение подписочного периода (сутки) составляет 15 рублей с НДС в сутки.
          Списание денежных средств с абонентского счета Пользователя происходит каждый день. В случае
          неполучения от Пользователя отказа от Сервиса, срок предоставления Сервиса услуги автоматически продлевается
          каждый раз на сутки при условии достаточного количества средств на лицевом счете до момента самостоятельного
          отключения Сервиса Пользователем. Отправка SMS-сообщений на номер 9303 не тарифицируется в домашней сети, но
          тарифицируется в роуминге в соответствии со стоимостью отправки SMS-сообщения по тарифному плану Пользователя.
          Мобильный Интернет-трафик, потребленный Пользователем при использовании Программного продукта и пользовании
          услугой «Подписка», тарифицируется в соответствии с тарифным планом Пользователя.</p>
        <p>3.2. В Сервисе предусмотрена возможность приобретения внутрисервисных ценностей - «Кристаллов», которые
          позволяют получить подсказки в играх, доступных в Сервисе. Стоимость Кристаллов зависит от их количества:</p>
        <p>10 «Кристаллов» - 5 рублей с НДС,</p>
        <p>50 «Кристаллов» - 20 рублей с НДС,</p>
        <p>300 «Кристаллов» - 100 рублей с НДС,</p>
        <p>1 000 «Кристаллов» - 250 рублей с НДС,</p>
        <p>2 500 «Кристаллов» - 500 рублей с НДС.</p>

        <p>3.3. Оплата за приобретение внутрисервисных ценностей («Кристаллов») осуществляется Пользователем отдельно от
          стоимости предоставления Сервиса, не является обязательным условием использования Сервиса и осуществляется
          Пользователем по его собственному усмотрению. Оплата за приобретение внутрисервисных ценностей осуществляется
          путем списания Администрацией Сервиса денежных средств в соответствующей сумме с лицевого счета абонента в
          биллинговой системе Оператора. Пользователь самостоятельно определяет количество приобретаемых «Кристаллов» и
          инициирует оплату посредством специальных инструментов, реализованных в интерфейсе Сервиса. В случае
          недостаточности денежных средств на лицевом счету Пользователя для оплаты полного количества «Кристаллов»,
          выбранного Пользователем, денежные средства с лицевого счета не списываются, внутрисервисный ценности
          («Кристаллы») Пользователю не начисляются. Частичная оплата выбранного Пользователей количества «Кристаллов»
          не допускается.</p>

        <p>3.4. Внутрисервисная ценность («Кристалл») представляет собой единицу объема прав Пользователя на
          использование дополнительного функционала Сервиса по предоставлению Пользователям подсказок при прохождении
          игр в Сервисе.</p>

        <p>3.5. «Кристаллы» учитываются Администрацией Сервиса в учетной записи Пользователя в Сервисе, имеющей привязку
          к абонентскому номеру телефона Пользователя, указанному при подключении Пользователем подписки на Сервис.</p>

        <p>3.6. Стоимость «Кристаллов» Пользователю не возвращается, в том числе в случае отключения Пользователем
          подписки на Сервис. «Кристаллы» могут быть использованы Пользователем в соответствии с логикой и сценарием
          Сервиса в течение всего срока предоставления Сервиса без ограничений.</p>

        <p>3.7. Внутрисервисная ценность («Кристалл») не является средством платежа, аналогом денежных средств, не
          является самостоятельным объектом имущественных прав и не может являться предметом сделок. Внутрисервисная
          ценность («Кристалл») не может быть передана другим лицам, в том числе другим пользователям Сервиса, по
          договорам купли-продажи, дарения, мены, уступки права или по иным сделкам.</p>

        <p>3.8. Пользователь может приобрести «Кристаллы» в обмен на игровые очки (в тексте Соглашения именуемые также
          «очки»), начисленные Пользователю за прохождение игр и/или выполнение иных действий в Сервисе. Правила и
          условия обмена очков на «Кристаллы» размещаются на соответствующих страницах и/или в разделах Сайта Сервиса и
          в интерфейсах Сервиса. Информация о приобретении «Кристаллов», списании очков отображается в профиле
          Пользователя на Сайте Сервиса.</p>
        <p>В тексте Соглашения «очки» означает условные единицы измерения, отображаемые в профиле Пользователя в
          Сервисе, характеризующие степень активности Пользователя по использованию Сервиса и используемые
          Администрацией Сервиса для определения объема прав Пользователя по использованию дополнительных функциональных
          возможностей Сервиса.</p>

        <p><strong>4. Порядок использования Сервиса</strong></p>
        <p>4.1. Регистрируясь в Сервисе, либо направляя запрос в Сервис, а также осуществляя регистрацию/авторизацию в
          Сервисе, использование Сервиса и/или Сайта Сервиса, Пользователь, принимая условия Соглашения:</p>

        <p>1) Использует Сервис на свой собственный риск. Сервис предоставляется «как есть». Администрация Сервиса не
          принимает на себя никакой ответственности, в том числе за соответствие Сервиса целям Пользователя.</p>
        <p>2) Гарантирует, что его возраст не менее 18 лет.</p>
        <p>3) Принимает к сведению и соглашается с тем, что Сервис может содержать материалы, ориентированные только на
          совершеннолетних Пользователей.</p>
        <p>4) Обязуется использовать Сервис в соответствии с законодательством Российской Федерации и Соглашением.</p>
        <p>5) Подтверждает, что проинформирован Администрацией Сервиса о том, что Пользователь может в любое время
          перестать пользоваться Сервисом без объяснения причин, следуя инструкциям, указанным в Соглашении.</p>
        <p>6) Соглашается с тем, что Сервис, включая программы для ЭВМ и базы данных, удаленный доступ к которым
          предоставляется Пользователю в рамках Сервиса, предназначен исключительно для личного некоммерческого
          использования.</p>
        <p>7) Имеет право подключить и использовать Сервис с использованием только одного абонентского номера телефона в
          сети связи Оператора. В случае выявления Администрацией Сервиса подключения или использования абонентом
          Сервиса с использованием второго и каждого последующего абонентского номера, Администрация Сервиса вправе
          прекратить предоставление Пользователю Сервиса с использованием всех абонентских номеров, кроме одного.</p>

        <p>4.2. Не допускаются или исключаются на любом этапе аккаунты в Сервисе, зарегистрированные Пользователем с
          использованием каких-либо ресурсов программирования. Пользователь не вправе использовать для участия в Сервисе
          или Акции программное обеспечение или механические, или электронные приборы и/или устройства, которые
          позволяют автоматически вносить данные на Сайте Сервиса, равно как и генерировать такие данные. Учетная
          запись, в которой указаны номера мобильных телефонов и электронные почты, зарегистрированные способами,
          указанными в настоящем пункте, Администрацией Сервиса аннулируются, а предоставление Сервиса использующим их
          Пользователем – прекращается, в том числе прекращается участие Пользователей в Акциях. </p>

        <p>4.3. Для получения доступа к Сервису с использованием Мобильного приложения билайн мобильное устройство
          Пользователя должно поддерживать операционные системы iOS (начиная с версии 14.0.0) или Android (начиная с
          версии 7.0.0). Для получения доступа к web-интерфейсу Сервиса устройство Пользователя должно поддерживать
          последние версии браузеров Safari, Firefox, Chrome, Opera. Сайт Сервиса и игры могут не запускаться в старых
          версиях указанных браузеров и браузере Internet Explorer.</p>

        <p>4.4. Для начала пользования Сервисом Пользователю необходимо воспользоваться одним из интерфейсов Сервиса,
          используя номер телефона в качестве идентификатора для входа. В процессе первого подключения Сервиса с
          указанием номера телефона осуществляется регистрация Пользователя в Сервисе. В дальнейшем Пользователь
          авторизуется в Сервисе путем указания номера телефона, если номер телефона автоматически не отображается в
          форме авторизации. Авторизация Пользователя в Сервисе осуществляется по номеру телефона в сети связи
          Оператора, с использованием которого Пользователем подключена подписка на Сервис.</p>

        <p><strong>5. Поощрения</strong></p>
        <p>5.1. При прохождении игр, совершении определенных действий по использованию Сервиса Пользователю
          Администрацией Сервиса могут предоставляться поощрения в рамках игрового процесса, логики и сценария Сервиса,
          реализованные как часть функционала Сервиса, или поощрения в виде промокодов на скидки в Интернет-магазинах/
          розничных торговых точках Администрации Сервиса (или партнеров Администрации Сервиса) или иных ценных призов
          (далее – Поощрения).</p>
        <p>5.2. Пользователю могут предоставляться следующие Поощрения:</p>
        <p>- начисление очков, которые в дальнейшем могут быть использованы для приобретения внутрисервисных ценностей
          («Кристаллов»),</p>
        <p>- поощрения в виде промокодов на скидки в Интернет-магазинах и другие ценные призы, которые предоставляются
          Администрацией Сервиса или третьими лицами,</p>
        <p>- фрагменты пазла для прохождения игр в Сервисе,</p>
        <p>- дополнительные возможности в Сервисе и другие Поощрения, определяемые по единоличному усмотрению
          Администрации Сервиса.</p>

        <p>5.3. Количество Поощрений ограничено. Перечень и количество Поощрений определяются Администрацией Сервиса по
          ее единоличному усмотрению. Поощрение, на получение которого имеет право Пользователь в каждом конкретном
          случае, определяется Администрацией Сервиса по ее единоличному усмотрению.</p>

        <p>5.4. Получение Пользователем Поощрений не гарантировано.</p>

        <p>5.5. Информация о перечне, количестве Поощрений и условиях их получения доводится до Пользователя посредством
          интерфейсов Сервиса, в том числе размещается в профиле Пользователя на Сайте Сервиса.</p>

        <p>5.6. Информация об исчерпании Поощрений и прекращении их выдачи (предоставления) доводится до сведения
          Пользователей в интерфейсах Сервиса/ на Сайте Сервиса.</p>

        <p>5.7. Администрация Сервиса не несет ответственности за качество товаров и/или услуг партнеров,
          предоставляющих Поощрения, а также за полноту и достоверность предоставленной партнером информации о
          Поощрениях и соответствие такой информации фактическим условиям получения товаров и услуг партнера. Информация
          о порядке, сроках и условиях получения и использования такого Поощрения, предоставленного третьим лицом
          (партнером), предоставляется Пользователю в интерфейсе Сервиса/ на Сайте Сервиса, в том числе в виде ссылки на
          Интернет-ресурс партнера.</p>

        <p>5.8. В случае, если Поощрение реализовано в виде функциональности Сервиса (игровых очков, фрагментов пазла и
          проч.), такое Поощрение может быть использовано Пользователем исключительно в рамках Сервиса в соответствии с
          его логикой и сценарием.</p>

        <p>5.9. Поощрения могут быть использованы (активированы) в течение 2 (двух) месяцев со дня их предоставления
          Участнику Администрацией Сервиса или партнером, если иной срок не указан в правилах использования Поощрений,
          размещенных партнером и доведенных до сведения Пользователя в соответствии с п. 6.7. Соглашения. В случае,
          если Поощрения не использованы (не активированы) в течение указанного срока, Пользователь утрачивает право
          воспользоваться ими, Поощрения аннулируются. Возмещение Участнику стоимости Поощрений и/или предоставление
          какой-либо компенсации не производится.</p>

        <p><strong>6. Отключение Сервиса</strong></p>
        <p>6.1. Пользователь вправе отказаться от использования Сервиса, для этого Пользователю необходимо отправить
          SMS-сообщение со словом «Стоп» на номер 9303.</p>

        <p><strong>7. Интеллектуальная собственность</strong></p>
        <p>7.1. Доступ к Сервису и интеллектуальной собственности, размещенной в нем, для Пользователей ограничен
          пределами Российской Федерации.Пользователь гарантирует, что находится на территории Российской Федерации и не
          осуществляет каких-либо действий, направленных на обход технических средств защиты, контролирующих доступ к
          результатам интеллектуальной собственности, размещенной в Сервисе.</p>

        <p>7.2. Все используемые и размещенные в Сервисе результаты интеллектуальной деятельности, а также сам Сервис,
          являются интеллектуальной собственностью их законных правообладателей и охраняются законодательством об
          интеллектуальной собственности Российской Федерации, а также соответствующими международными правовыми
          конвенциями. Любое использование размещенных в рамках Сервиса результатов интеллектуальной деятельности (в том
          числе программ для ЭВМ и баз данных, а также элементов оформления Сервиса, символики, текстов, графических
          изображений, иллюстраций, фото, видео, программ, музыки и других объектов) без разрешения Администрации
          Сервиса или законного правообладателя является незаконным и может послужить причиной для судебного
          разбирательства и привлечения нарушителей к гражданско-правовой, административной и уголовной ответственности
          в соответствии с законодательством Российской Федерации.</p>

        <p>7.3. Любое использование Сервиса, кроме разрешенного в Соглашении, без предварительного письменного
          разрешения Администрации категорически запрещено.</p>

        <p>7.4. Все материалы Сервиса предназначены исключительно для личного некоммерческого использования в рамках
          объявленных технических возможностей, предоставляемых Сервисом. Любое копирование, воспроизведение,
          переработка, распространение, доведение до всеобщего сведения либо иное использование материалов Сервиса вне
          рамок возможностей, предоставляемых Сервисом, а также любое их использование в коммерческих целях запрещается.
          Запрещается: вскрывать технологию, эмулировать, декомпилировать, дизассемблировать, дешифровать и производить
          иные аналогичные действия с Сервисом и его программными компонентами; создавать программные продукты и/или
          сервисы с использованием Сервиса без получения предварительного разрешения Администрации Сервиса.</p>

        <p><strong>8. Ограничения и ответственность</strong></p>
        <p>8.1. Администрация Сервиса оставляет за собой право модифицировать Сервис (с письменного согласования
          правообладателя Сервиса). Администрация Сервиса не несет ответственности за причинение вреда, ущерба, потерю
          информации или за причинение любых других убытков любым лицам, которые возникли при пользовании Сервисом, в
          том числе с использованием мобильных средств связи и иных средств телекоммуникаций. Администрацией Сервиса
          осуществляется предоставление Сервиса исключительно для его использования Пользователем в личных
          некоммерческих целях, не связанных с осуществление предпринимательской или иной деятельности, направленной на
          извлечение прибыли.</p>

        <p>8.2. Обращения, предложения и претензии Пользователя в связи с использованием Сервиса, возможными нарушениями
          прав и интересов третьих лиц при его использовании, а также запросы уполномоченных законодательством
          Российской Федерации лиц могут быть направлены на электронный адрес support@via-mobi.ru.</p>

        <p><strong>9. Персональные данные</strong></p>
        <p>9.1. Принимая Соглашение, осуществляя подключение подписки на Сервис или используя Сервис, в том числе
          проходя регистрацию/ авторизацию в Сервисе, Пользователь свободно, своей волей и в своих интересах дает
          добровольное, осознанное согласие на следующие способы обработки Персональной информации: запись,
          систематизация, хранение, уточнение (обновление, изменение), извлечение, использование, передача
          (предоставление, доступ) третьим лицам, обезличивание, блокирование, удаление, уничтожение в целях и в
          порядке, установленных Соглашением, с использованием средств автоматизации или без использования таких средств
          по усмотрению Администрации Сервиса.</p>

        <p>9.2. Под Персональной информацией в Соглашении понимается:</p>
        <p>- информация, которую Пользователь предоставляет о себе самостоятельно при подключении и использовании
          Сервиса, при регистрации или авторизации в Сервисе, в процессе использования Сервиса, в том числе персональные
          данные Пользователя, включая номер телефона, личную фотографию, фамилию/ имя/ отчество или псевдоним
          Пользователя (если возможность предоставления таких данных функционально реализована в Сервисе);</p>
        <p>- технические данные, которые передаются в автоматическом режиме в зависимости от настроек программного
          обеспечения на мобильном устройстве Пользователя, в том числе файлы cookie;</p>
        <p>- данные об использовании Сервиса, в том числе о его подключении/ отключении/ приостановлении предоставления
          доступа к Сервису, об активности пользователя в Сервисе, о пользовательском опыте (результатах) Пользователя
          при использовании функционала Сервиса, приобретении внутрисервисных ценностей, количестве начисленных очков
          средствами Сервиса и проч.; тексты SMS-сообщений, ussd-команды, другие сообщения технического характера,
          направляемые Пользователем или Администрацией Сервиса при использовании Пользователем Сервиса;</p>
        <p>- данные, собираемые и передаваемые метрическим сервисом Яндекс.Метрика, осуществляющим сбор и обработку
          данных в соответствии с политикой конфиденциальности, размещенной в сети Интернет по адресу:
          https://yandex.ru/legal/confidential/. Указанные данные могут включать данные об устройстве Пользователя
          (модель, производитель, объем свободной памяти), место его положения (страна, город), данные операционной
          системы (тип, версия, разрешение экрана) и другие; данные запроса (время, источник перехода, IP-адрес); иные
          обезличенные технические данные об устройстве Пользователя или о Пользователя; данные о действиях Пользователя
          в Сервисе, в том числе получаемые с помощью передачи файлов cookie.</p>

        <p>9.3. Администрация Сервиса устанавливает требования к составу Персональной информации Пользователя, которая
          должна обязательно предоставляться для подключения подписки на Сервис и/или использования Сервиса. Если в
          отношении определенной информации в интерфейсе Сервиса не указано на обязательность предоставления, такая
          информация предоставляется (раскрывается) Пользователем по его собственному усмотрению.</p>

        <p>9.4. В зависимости от технических настроек на мобильном устройстве Пользователя, данные об абонентском
          номере телефона Пользователя могут в автоматическом режиме передаваться Администрации Сервиса при подключении
          Сервиса и дальнейшем использовании Сервиса (в процессе авторизации в Сервисе). В этом случае Пользователь не
          указывает в Интерфейсе Сервиса/Сайте Сервиса или в Мобильном приложении абонентский номер телефона, но дает
          согласие на обработку данных об абонентском номере телефона в соответствии с настоящим разделом
          Соглашения.</p>

        <p>9.5. Размещая в Сервисе, в том числе на Сайте Сервиса или в Мобильном приложении, Персональную информацию, в
          том числе свои персональные данные, Пользователь осознает и соглашается с тем, что указанная информация может
          быть доступна другим пользователям сети Интернет с учетом особенностей интерфейса и функционала Сервиса.
          Пользователь осуществляет использование функционала Сервиса, в том числе путем размещения в нем персональных
          данных (включая фотографии, данные о фамилии, имени, отчестве Пользователя или его псевдониме) по своему
          усмотрению и на свой риск. Администрация Сервиса принимает технические и организационные меры по обеспечению
          функционирования соответствующего функционала Сервиса.</p>

        <p>9.6. Администрация Сервиса не инициирует и не влияет на такой выбор Пользователя, а также не имеет цели
          получить у Пользователя разрешение на распространение его персональных данных. Обработка персональных данных,
          раскрытых неопределенному кругу лиц самим Пользователем (субъектом персональных данных), осуществляется
          Администрацией Сервиса на основании и в соответствии с условиями Соглашения.</p>

        <p>9.7. Администрация Сервиса не несет ответственности за использование и разглашение персональных данных
          Пользователя другими Пользователями Сервиса или иными третьими лицами, получившими доступ к таким данным
          посредством Сервиса, и за последствия использования информации, которая, в силу назначения и особенностей
          реализации Сервиса, доступна любому пользователю сети Интернет. Администрация Сервиса рекомендует
          Пользователям ответственно подходить к решению вопроса об объеме и содержании контента и персональных данных,
          размещаемых в Сервисе.</p>

        <p>9.8. Администрация Сервиса и третье лицо, указанное в п. 10.9. Соглашения, осуществляют обработку
          Персональной информации Пользователя в целях заключения и исполнения Соглашения, других договоров по
          инициативе Пользователя; предоставления Сервиса; улучшения Сервиса; технической и информационной поддержки Пользователей с целью
          бесперебойного и качественного функционирования Сервиса, а также информирования Пользователя о событиях,
          связанных с подключением и использованием Сервиса; идентификация Пользователя при подключении и использовании
          Сервиса; обеспечение безопасности и конфиденциальности Персональной информации. Администрация Сервиса, вправе
          осуществлять обработку персональных данных Пользователя, также в целях исполнения налоговых обязанностей по
          предоставлению в налоговые органы сведений о доходе в виде призов, полученном Пользователями, признанными
          победителями Акций, а также иных обязанностей, установленных Налоговым кодексом РФ в связи с предоставлением
          призов Пользователям.</p>

        <p>9.9. Пользователь даёт своё согласие на передачу Администрацией Сервиса Персональной информации
          Пользователя, указанной в п. 10.2. Соглашения, третьему лицу - ООО «ВиаМоби» (место нахождения 129626, г.
          Москва, Проспект Мира, д. 102, корп. 1, офис 706; ИНН 7725780914; ОГРН 1137746059593) и ее обработку указанным
          третьим лицом, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
          извлечение, использование, обезличивание, блокирование, удаление, уничтожение указанных данных, в целях,
          указанных в п. 10.8. Соглашения.</p>

        <p>9.10. Пользователь не вправе предоставлять Администрации Сервиса персональные данные третьих лиц без их
          согласия в соответствии с Федеральным законом Российской Федерации «О персональных данных».</p>

        <p>9.11. Соглашение регулируется законодательством Российской Федерации, в том числе в части обработки
          персональных данных, а также политикой обработки персональных данных в ПАО «ВымпелКом», которая размещена на
          <a href="https://beeline.ru/customers/legal/service-and-sale/.">https://beeline.ru/customers/legal/service-and-sale/</a>.
        </p>

        <p>9.12. Администрация Сервиса и лицо, указанное в п.10.9. Соглашения, вправе осуществлять обработку
          персональной информации Пользователя на условиях Соглашения в течение всего срока использования Пользователем
          Сервиса и после прекращения такого использования - в течение срока исковой давности по обязательствам,
          возникающим из Соглашения и заключаемых на его основе договоров.</p>

        <p><strong>10. Заключительные положения</strong></p>
        <p>10.1. Соглашение регулируется законодательством Российской Федерации, в том числе в части обработки
          персональных данных. Вопросы, не урегулированные Соглашением, разрешаются в соответствии с законодательством
          Российской Федерации.</p>

        <p>10.2. Все споры, возникающие в связи с использованием Сервиса, разрешаются Пользователем и Администрацией
          Сервиса в досудебном порядке путем переговоров.</p>
        <p>Если какое-либо условие Соглашения будет признано судом или любым другим компетентным органом
          недействительным, незаконным или не имеющим силу, то это не повлияет на действительность, законность и
          действие остальных условий Соглашения.</p>

        <p>10.3. Соглашение может быть в одностороннем порядке изменено Администрацией Сервиса путем размещения новой
          редакции Соглашения на сайте beeline.ru или krossvord.beeline.ru. Новая редакция Соглашения вступает в силу и
          применяется к отношениям Сторон с момента ее размещения по указанной ссылке.</p>

        <p><strong>Приложение № 1 к Пользовательскому соглашению
          сервиса «Мир кроссвордов»</strong></p>

        <p>Таблица 1</p>
        <table>
          <tr>
            <th colspan="3">Перечень поощрений, при сборе пазла</th>
          </tr>
          <tr>
            <th>Название поощрения</th>
            <th>Скидка</th>
            <th>Число призов</th>
          </tr>
          <tr>
            <td>Скидка до 100% на покупку лимитированного стаканчика для кофе от билайна</td>
            <td>до 100%</td>
            <td>10</td>
          </tr>
          <tr>
            <td>1000 кристаллов*</td>
            <td>1000</td>
            <td>неограничено</td>
          </tr>
        </table>

        <p>*Кристаллы - внутрисервисные ценности</p>
      </div>


    </div>
  </div>
</template>

<script>

import {RouterLink, RouterView} from 'vue-router'

export default {
  name: 'AppHeader',
  components: {
    RouterLink,
    RouterView
  },
};
</script>
