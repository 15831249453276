<template>

  <div class="puzzle-grid" :class="{ 'completed': puzzle.openedItems.length == 25 }">
    <div class="puzzle-grid-bg-img" v-if="this.puzzle.image" :style="{ backgroundImage: 'url(' + staticPrefix+this.puzzle.image + ')' }"></div>
    <div class="puzzle-grid-list">
      <div class="puzzle-grid-item" v-for="item in grid" :class="{'active': item===1}"></div>
    </div>
  </div>

</template>


<script>
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/store/user';

export default {
  name: 'puzzleProgress',
  props: {
    puzzle: {
      type: Object,
      default: {}
    },

  },
  components: {

  },
  data() {
    return {
     // grid: []
    };
  },
  computed: {
    ...mapState(useUserStore, ['staticPrefix']),
    grid(){
      let grid = [];
      if (this.puzzle.openedItems){
        for (let i=0; i < this.puzzle.qty; i++){
          if ( this.puzzle.openedItems.includes(i+1) )
            grid.push(1)
          else
            grid.push(0)
        }
      }

      return grid;
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
};
</script>
