<template>
  <div class="nav-container">
    <RouterLink class="nav-item" to="/">
      <span class="dot"></span>
      <div class="nav-icon">
        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.625 11.673C1.625 10.1428 2.32572 8.6968 3.52678 7.7486L10.4018 2.32097C12.2183 0.886851 14.7817 0.88685 16.5982 2.32096L23.4732 7.74859C24.6743 8.6968 25.375 10.1428 25.375 11.673V19.875C25.375 22.6364 23.1364 24.875 20.375 24.875H18.5C17.8096 24.875 17.25 24.3154 17.25 23.625V19.875C17.25 18.4943 16.1307 17.375 14.75 17.375H12.25C10.8693 17.375 9.75 18.4943 9.75 19.875V23.625C9.75 24.3154 9.19036 24.875 8.5 24.875H6.625C3.86358 24.875 1.625 22.6364 1.625 19.875L1.625 11.673Z" stroke="#A7A7A7" class="base-stroke-gray base-transparent active-yellow active-yellow-stroke" stroke-width="1.875"/>
        </svg>
      </div>
      <div class="nav-title">{{ $t("nav.menu.home") }}</div>
    </RouterLink>
    <RouterLink class="nav-item" to="/shop">
      <span class="dot"></span>
      <div class="nav-icon">
        <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 1.75L2.8117 2.01234C3.8892 2.22784 4.69966 3.12165 4.809 4.21503L5 6.125M5 6.125L6.23574 16.4229C6.38652 17.6794 7.45243 18.625 8.71794 18.625H19.9591C21.9666 18.625 23.7166 17.2587 24.2035 15.3111L25.6069 9.69729C26.0606 7.88274 24.6882 6.125 22.8178 6.125H5Z" class="base-stroke-gray active-yellow-stroke active-yellow" stroke-width="1.875" stroke-linecap="round"/>
          <path d="M15.25 14.875H10.25" class="base-stroke-gray active-stroke-white" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
          <circle cx="9.625" cy="23" r="1.875" class="base-gray active-yellow" />
          <circle cx="20.875" cy="23" r="1.875" class="base-gray active-yellow" />
        </svg>
      </div>
      <div class="nav-title">{{ $t("nav.menu.shop") }}</div>
    </RouterLink>
    <RouterLink class="nav-item" to="/profile">
      <span class="dot"></span>
      <div class="nav-icon">
        <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="5" transform="matrix(-1 0 0 1 15 1.75)" class="base-stroke-gray active-yellow active-yellow-stroke" stroke-width="1.875"/>
          <path d="M1.25 19.1683C1.25 18.0929 1.92607 17.1335 2.93886 16.7718V16.7718C7.50505 15.1411 12.4949 15.1411 17.0611 16.7718V16.7718C18.0739 17.1335 18.75 18.0929 18.75 19.1683V20.8127C18.75 22.297 17.4354 23.4372 15.966 23.2273L14.773 23.0569C11.6071 22.6046 8.39294 22.6046 5.22703 23.0569L4.03401 23.2273C2.56463 23.4372 1.25 22.297 1.25 20.8127V19.1683Z" class="base-stroke-gray active-yellow active-yellow-stroke" stroke-width="1.875"/>
        </svg>
      </div>
      <div class="nav-title">{{ $t("nav.menu.profile") }}</div>
    </RouterLink>
    <RouterLink class="nav-item" to="/langs">
      <span class="dot"></span>
      <div class="nav-icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="12" fill="#FFA031" class="base-transparent active-yellow" style="transform: scale(.9);transform-origin: center;"/>
          <path d="M12 0C8.81743 0 5.76521 1.26433 3.5148 3.5148C1.26439 5.76521 0 8.81754 0 12C0 15.1825 1.26433 18.2348 3.5148 20.4852C5.76521 22.7356 8.81754 24 12 24C15.1825 24 18.2348 22.7357 20.4852 20.4852C22.7356 18.2348 24 15.1825 24 12C24 8.81754 22.7357 5.76521 20.4852 3.5148C18.2348 1.26439 15.1825 0 12 0ZM21.7704 7.57897H16.5598C16.1872 5.14731 15.4987 2.96202 14.5135 1.57264C16.099 1.95856 17.5763 2.69974 18.8335 3.74017C20.0907 4.78081 21.0949 6.09338 21.7704 7.57892V7.57897ZM15.6314 12.0001H15.6312C15.6316 13.0551 15.5747 14.1093 15.4608 15.1579H8.51355C8.28601 13.0589 8.28601 10.9413 8.51355 8.84249H15.4608C15.5747 9.89115 15.6316 10.9453 15.6312 12.0003L15.6314 12.0001ZM11.9997 1.26341C13.2628 1.26341 14.665 3.71391 15.2903 7.5794L8.70905 7.57919C9.33437 3.71402 10.7366 1.26374 11.9997 1.26374V1.26341ZM9.47328 1.57279C8.48807 2.96234 7.79952 5.14763 7.4269 7.57912H2.22903C2.90318 6.09505 3.90555 4.78329 5.16036 3.74292C6.41522 2.70249 7.88998 1.96045 9.47323 1.57284L9.47328 1.57279ZM1.26309 12.0004C1.26436 10.9301 1.42402 9.86602 1.73678 8.84254H7.27592C7.04859 10.9415 7.04859 13.0592 7.27592 15.158H1.73678C1.42402 14.1345 1.26436 13.0704 1.26309 12.0002V12.0004ZM2.22952 16.4215H7.44006C7.81266 18.8532 8.50122 21.0385 9.48643 22.4278H9.48622C7.90065 22.0419 6.42334 21.3007 5.16616 20.2603C3.90897 19.2197 2.90475 17.9071 2.22931 16.4216L2.22952 16.4215ZM11.9999 22.737C10.7368 22.737 9.33458 20.2865 8.70927 16.421L15.2905 16.4212C14.6652 20.2863 13.263 22.7366 11.9999 22.7366V22.737ZM14.5263 22.4276C15.5115 21.038 16.2 18.8527 16.5727 16.4212H21.7832H21.783C21.1078 17.9068 20.1035 19.2194 18.8464 20.26C17.5892 21.3004 16.1119 22.0416 14.5263 22.4275L14.5263 22.4276ZM16.7241 15.1577C16.8381 14.1091 16.8949 13.0549 16.8947 11.9999C16.8949 10.9449 16.8381 9.89072 16.7241 8.84206H22.2632C22.8966 10.8996 22.8966 13.1001 22.2632 15.1575L16.7241 15.1577Z" class="base-gray active-white"/>
        </svg>
      </div>
      <div class="nav-title">{{ $t("nav.menu.lang") }}</div>
    </RouterLink>
  </div>
</template>

<script>
  import { RouterLink, RouterView } from 'vue-router'

  export default {
    name: 'Nav',
    components: {
      RouterLink,
      RouterView
    },
  };
</script>
