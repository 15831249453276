<template>
    <div :class='["game-difficulty", addStyles]'>
        <label class="difficulty-check">
          <input type="radio" v-model="difficulty" @change="emitChange" value="low">
          <div class="difficulty-check-label">Легко</div>
        </label>
        <label class="difficulty-check">
          <input type="radio" v-model="difficulty" @change="emitChange" value="middle">
          <div class="difficulty-check-label">Средне</div>
        </label>
        <label class="difficulty-check">
          <input type="radio" v-model="difficulty" @change="emitChange" value="high">
          <div class="difficulty-check-label">Сложно</div>
        </label>
        <label class="difficulty-check">
          <input type="radio" v-model="difficulty" @change="emitChange" value="ultra">
          <div class="difficulty-check-label">Экстремально</div>
        </label>
        <label class="difficulty-check">
          <input type="radio" v-model="difficulty" @change="emitChange" value="all">
          <div class="difficulty-check-label">Все</div>
        </label>
      </div>
</template>
  
  
  <script>

  export default {
    name: 'gameDifficulty',
    data() {
        return {
            difficulty: 'low'
        }
    },  
    props: {
      addStyles: {
        type: String,
        default: '',
      }
    },
    methods: {
        emitChange() {

            this.$emit('changeLevel', this.difficulty);
        },
    }
  };
  </script>
  