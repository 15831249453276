<template>
  <VueFinalModal
      class="modal-wrapper"
      content-class="modal-container"
      overlay-class="modal-overlay"
      @update:model-value="val => $emit('close')"
  >
    <div class="modal-close" @click="$emit('close')"><span class="icon-close"></span></div>
    <div v-if="title" class="modal-title">
      {{ title }}
    </div>
    <slot name="customHeader"></slot>
    <div class="modal-content">
      <slot />
    </div>
    <slot name="customFooter"></slot>
    <div class="modal-buttons" v-if="btnText">
      <button :class="['btn btn-primary', btnClass]"  @click="$emit('confirm')">
        {{ btnText }}
      </button>
    </div>
  </VueFinalModal>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import { VueFinalModal } from 'vue-final-modal'
import { useMainStore } from '@/store';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    },
    btnClass: {
      type: String,
      default: ''
    },
    star: {
      type: Boolean,
      default: false
    }

  },
  emits: ['close','confirm'],
  components: {
    VueFinalModal
  },
  methods: {

  }
};
</script>
