<template>

  <div class="sudoku-cell" :class="{ locked: item.locked, error: item.error, highlighted: item.highlighted, tipCell: item.tipsCell}" :tabindex="item.locked ? -1 : 0" ref="cell" @focus="$emit('pickCell',item)" @keydown="handleKeydown">
    <span v-if="item.value!=0">{{item.value}}</span>
  </div>

</template>


<script>
import { mapState, mapActions } from 'pinia';

export default {
  name: 'cell',
  props: {
    item: {
      type: Object,
      default: {}
    },
  },
  emits: ['pickCell', 'clearCell', 'inputCell'],
  components: {

  },
  data() {
    return {

    };
  },
  computed: {

  },
  created() {

  },
  methods: {
    handleKeydown(e) {
      if (e.key === "Delete" || e.key === "Backspace") {
        this.$emit('inputCell',{
          num: 0,
          el: this.item
        })
      }
      if (/[1-9]/.test(e.key)) {
        this.$emit('inputCell',{
          num: e.key,
          el: this.item
        })

      }
    },
  }
};
</script>
